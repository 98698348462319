import ReferralsListQuery from './ReferralsList'
import ReferralStatsQuery from './ReferralStats'
import UpdateReferralBonusMutation, {
  UpdateReferralBonusShareRatioDto,
} from 'src/graphql/mutations/updateReferralBonus'
import { Referral, ReferralsListRequestType, ReferralStats, ReferralStatsRequestType } from './types'

export type {
  Referral,
  ReferralsListRequestType,
  ReferralStats,
  ReferralStatsRequestType,
  UpdateReferralBonusShareRatioDto,
}
export { ReferralsListQuery, ReferralStatsQuery, UpdateReferralBonusMutation }
