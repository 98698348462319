import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { BUILD_MODE } from 'src/utils/webAdapters/DotEnvAdapter'
import { Platform, Share, Text, View } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'

import { applyFormProductionUrl, applyFormStagingUrl } from 'src/global/constants'
import { bugsnagActionBreadcrumb, bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { countryCodeSelector } from 'src/store/app/selectors'
import { Educator } from 'src/graphql/Educator'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { openURLIfCan } from 'src/global/utils'
import { useAppSelector } from 'src/hooks/reduxHooks'
import ActionsModal from 'src/components/ActionsModal'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import CheckCircleFilledIcon from 'src/icons/redesign/checkCircleFilled'
import styles from './styles'
import TermsAndConditionsModal from '../TermsAndConditionsModal'
import translations, { translate } from 'src/utils/translations/translations'

const isWeb = Platform.OS === 'web'
const isStaging = BUILD_MODE === 'staging'

type Props = {
  educator: Educator | undefined
  updateReferralsTermsAndConditions: () => void
}

const ReferralShareButtons = ({ educator, updateReferralsTermsAndConditions }: Props) => {
  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false)
  const [isConfirmationVisible, setisConfirmationVisible] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [link, setLink] = useState<string | null>(null)
  const pendingAction = useRef<(referralLink: string) => void>()
  const pendingActionTimer = useRef<NodeJS.Timeout>()
  const timer = useRef<NodeJS.Timeout>()

  const countryCode = useAppSelector(countryCodeSelector)

  const { mixpanel } = useContext(MixpanelContext)

  useEffect(() => {
    if (educator && educator.referralCode) {
      const { referralCode, firstName, lastName } = educator
      const params = new URLSearchParams({
        referralCode,
        firstName,
        lastName,
      })

      const applyFormUrl = isStaging ? applyFormStagingUrl[countryCode] : applyFormProductionUrl[countryCode]
      const referralLink = `${applyFormUrl}/?${params.toString()}`

      setLink(referralLink)

      if (pendingAction.current) {
        pendingActionTimer.current = setTimeout(() => {
          pendingAction.current?.(referralLink)
        }, 500)
      }
    }
  }, [countryCode, educator])

  useEffect(() => {
    return () => {
      pendingAction.current = undefined
      pendingActionTimer.current && clearTimeout(pendingActionTimer.current)
      timer.current && clearTimeout(timer.current)
    }
  }, [])

  const toggleIsActionsModalOpen = useCallback(
    () => setIsActionsModalOpen(prevState => !prevState),
    [setIsActionsModalOpen],
  )

  const toggleIsTermsAndConditionsModalOpen = useCallback(() => setShowModal(prevState => !prevState), [setShowModal])

  const sendEmailMessage = () => {
    if (link) {
      const subject = encodeURIComponent(translate(translations.referralShareInfoTitle))
      const body = encodeURIComponent(translate(translations.referralShareInfo, { link }))

      openURLIfCan(`mailto:?subject=${subject}&body=${body}`)
    }
  }

  const modalActions = useMemo(
    () => [
      {
        label: translate(translations.emailSend),
        onPress: () => {
          sendEmailMessage()
          toggleIsActionsModalOpen()
        },
      },
      {
        label: translate(translations.cancel),
        onPress: toggleIsActionsModalOpen,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleIsActionsModalOpen, link],
  )

  const handleCopyLink = useCallback(
    (referralLink: string | null) => {
      if (referralLink) {
        mixpanel?.track('Copy referral link', {
          teacherReferralLink: referralLink,
        })

        Clipboard.setString(referralLink)
        setisConfirmationVisible(true)
        timer.current = setTimeout(() => setisConfirmationVisible(false), 2000)
      }
    },
    [mixpanel],
  )

  const handleShareAction = useCallback(
    async (referralLink: string | null) => {
      if (referralLink) {
        mixpanel?.track('Share referral link', {
          teacherReferralLink: referralLink,
        })

        if (isWeb) {
          toggleIsActionsModalOpen()
        } else {
          try {
            const result = await Share.share(
              {
                message: translate(translations.referralShareInfo, { link: referralLink }),
              },
              {
                subject: translate(translations.referralShareInfoTitle),
              },
            )

            if (result.action === Share.sharedAction) {
              bugsnagActionBreadcrumb('Share referral link - action succeeded')
            } else if (result.action === Share.dismissedAction) {
              bugsnagActionBreadcrumb('Share referral link - user dismissed the action')
            }
          } catch (error) {
            bugsnagNotifyWithData('Share referral link - action errored', { error })
          }
        }
      }
    },
    [mixpanel, toggleIsActionsModalOpen],
  )

  const handleButtonPress = useCallback(
    (action: (referralLink: string | null) => void) => {
      if (educator?.acceptedReferralsTermsAndConditions) {
        action(link)
      } else {
        pendingAction.current = action

        toggleIsTermsAndConditionsModalOpen()
      }
    },
    [educator?.acceptedReferralsTermsAndConditions, toggleIsTermsAndConditionsModalOpen, link],
  )

  const handleAcceptTerms = useCallback(() => {
    updateReferralsTermsAndConditions()
    toggleIsTermsAndConditionsModalOpen()
  }, [toggleIsTermsAndConditionsModalOpen, updateReferralsTermsAndConditions])

  return (
    <>
      <ButtonNew
        disabled={!educator}
        onPress={() => handleButtonPress(handleShareAction)}
        size={buttonSize.lg}
        title={translate(translations.share)}
        variant={buttonVariants.containedDefault}
      />
      <ButtonNew
        disabled={!educator}
        onPress={() => handleButtonPress(handleCopyLink)}
        size={buttonSize.lg}
        title={translate(translations.copyLink)}
        variant={buttonVariants.outlinedDefault}
      />
      <View style={styles.copyConfirmationWrapper}>
        {isConfirmationVisible && (
          <>
            <Text style={styles.copyConfirmationText}>{translate(translations.linkCopied)}</Text>
            <CheckCircleFilledIcon size={14} />
          </>
        )}
      </View>
      <ActionsModal actions={modalActions} isVisible={isActionsModalOpen} onClose={toggleIsActionsModalOpen} />
      <TermsAndConditionsModal
        handleClose={toggleIsTermsAndConditionsModalOpen}
        handleSubmit={handleAcceptTerms}
        hasAcceptedTerms={educator?.acceptedReferralsTermsAndConditions}
        showModal={showModal}
      />
    </>
  )
}

export default ReferralShareButtons
