import React from 'react'
import get from 'lodash/get'
import { View, StyleSheet, TextStyle, StyleProp, ViewStyle } from 'react-native'
import { FieldInputProps, FormikProps } from 'formik'

import { Error, Label } from 'src/components/Form/Common'
import translations, { translate } from 'src/utils/translations/translations'

import styles from './styles'
import Radio from './Radio'

const defaultOptions = [
  {
    value: true,
    label: translate(translations.yes),
  },
  {
    value: false,
    label: translate(translations.no),
  },
]

type Props = {
  containerStyle?: StyleProp<ViewStyle>
  field: FieldInputProps<string | boolean>
  form: FormikProps<any>
  label: string
  labelStyles?: StyleProp<TextStyle>
  options: typeof defaultOptions
  required: boolean
}

export const InputRadioGroup = ({
  containerStyle,
  field: { name, value },
  form: { errors, touched, setFieldValue },
  label,
  labelStyles,
  options = defaultOptions,
  required,
}: Props) => {
  const isTouched = get(touched, name)

  const handleChange = (valueChange: boolean) => setFieldValue(name, valueChange)

  const isErrors = errors && Object.keys(errors).length

  return (
    <View style={StyleSheet.flatten([styles.radioGroupContainer, containerStyle])}>
      {label && <Label labelStyles={labelStyles} label={label} required={required} />}
      {options.map((option, index) => (
        <Radio
          key={index}
          label={option.label}
          onPress={() => handleChange(option.value)}
          selected={option.value === value}
          value={option.value}
        />
      ))}

      {isErrors ? <Error errors={isTouched ? errors : {}} name={name} /> : null}
    </View>
  )
}

export default InputRadioGroup
