import { useEffect, useRef, useState } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { bugsnagNotifyWithData } from 'src/utils/bugsnag'
import { useAppSelector } from './reduxHooks'
import Api, { responseErrorCallback } from 'src/utils/api'
import formatter from 'src/utils/formatter'
import OnHoldQuery, { UserOnHoldRequestType } from 'src/graphql/User/OnHold'

const useOnHoldData = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [successfullyEndedOnHold, setSuccessfullyEndedOnHold] = useState(false)
  const timer = useRef<NodeJS.Timeout>()

  const userId = useAppSelector(state => state.user?.id)

  const { data, refetch } = useQuery<UserOnHoldRequestType>(OnHoldQuery, {
    variables: {
      id: userId,
    },
    skip: !userId,
    fetchPolicy: 'network-only',
  })

  const onHoldAt = data?.user?.onHoldAt || ''
  const onHoldAtFormatted = formatter.shortDate(onHoldAt)
  const isOnHold = moment(onHoldAt).isSameOrBefore(moment(), 'day')
  const onHoldReason = data?.user?.onHoldReason

  const endOnHoldState = async () => {
    setSuccessfullyEndedOnHold(false)
    setIsLoading(true)

    await Api.put(`/student/on_holds/${userId}`, {
      data: {
        user: {
          onHoldAt: null,
          onHoldById: null,
          onHoldEndDate: null,
          onHoldNote: null,
          onHoldReason: null,
        },
      },
      onSuccess: async () => {
        setSuccessfullyEndedOnHold(true)
        await refetch()
      },
      onError: ({ response }) => {
        bugsnagNotifyWithData('endOnHoldState', response)
        responseErrorCallback('put', `/student/on_holds/${userId}`, response)
      },
    })

    timer.current = setTimeout(() => setSuccessfullyEndedOnHold(false), 5000)
    setIsLoading(false)
  }

  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current)
  }, [])

  return {
    endOnHoldState,
    isLoading,
    isOnHold,
    onHoldAt,
    onHoldAtFormatted,
    onHoldReason,
    refetch,
    successfullyEndedOnHold,
  }
}

export default useOnHoldData
