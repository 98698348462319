import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, GestureResponderEvent } from 'react-native'
import styles from './styles'

type Props = {
  selected: boolean
  onPress: (event: GestureResponderEvent) => void
  label: string
}

export const Radio = ({ selected, onPress, label }: Props) => (
  <TouchableOpacity
    onPress={onPress}
    style={
      selected
        ? StyleSheet.flatten([styles.radioButtonContainer, styles.radioButtonContainerSelected])
        : styles.radioButtonContainer
    }
  >
    <View
      onPress={onPress}
      style={!selected ? styles.radioButton : StyleSheet.flatten([styles.radioButton, styles.radioButtonSelected])}
    >
      {selected ? <View style={styles.radioButtonIcon} /> : null}
    </View>
    <Text style={styles.radioButtonText}>{label}</Text>
  </TouchableOpacity>
)

export default Radio
