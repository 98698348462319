import React, { useRef, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { capitalizeFirstLetter } from 'src/global/utils'
import { getReferAnimationVariant } from '../utils'
import { isGBSelector } from 'src/store/app/selectors'
import { MoneyBagSvIcon, MoneyBagUKIcon } from 'src/icons'
import { ReferralShareButtons } from '../components'
import { referralsStates } from './constants'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import globalStyles from 'src/global/globalStyles'
import LoadingSection from './components/LoadingSection'
import MoneyInfo, { moneyInfoVariant } from './components/MoneyInfo'
import ReferralSection from './components/ReferralSection'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useEducatorData from 'src/hooks/useEducatorData'
import useReferralsData from 'src/hooks/useReferralsData'

const referralsList = [
  referralsStates.paidOut,
  referralsStates.educatorAccepted,
  referralsStates.educatorInRecruitment,
  referralsStates.educatorLeftHumly,
] as const

const ReferralsList = () => {
  const [listHeight, setListHeight] = useState(0)

  const {
    educator,
    error: educatorError,
    loading: educatorLoading,
    refetch: educatorRefetch,
    updateReferralsTermsAndConditions,
  } = useEducatorData()

  const {
    error: referralsError,
    fetchMoreReferrals,
    isNoReferrals,
    loading: referralsLoading,
    moneyInfo,
    referrals,
    referralsListHasNextPage,
    refetchOnError: refetchReferrals,
  } = useReferralsData({ skipFetchingData: false })

  const educatorId = useAppSelector(state => state.user.id)

  const { earnedMoney, earnedMoneyRise, givenMoney, givenMoneyRise } = moneyInfo

  const listRef = useRef<ScrollView>(null)

  const isGB = useAppSelector(isGBSelector)
  const MoneyBagIcon = isGB ? MoneyBagUKIcon : MoneyBagSvIcon

  const animationVariant = getReferAnimationVariant({ earnedMoney, givenMoney })

  const isLoading = educatorLoading || referralsLoading
  const playAnimation = !isLoading && !isNoReferrals

  const handleUpdateReferralsTermsAndConditions = async () => {
    await updateReferralsTermsAndConditions({ variables: { update: { educatorId, value: true } } })
    await educatorRefetch()
  }

  const renderReferrals = () => {
    if (isLoading) {
      return <LoadingSection />
    }

    if (isNoReferrals) {
      return (
        <>
          <View style={styles.emptyViewWrapper}>
            <MoneyBagIcon size={67} />
            <Text style={styles.info}>{translate(translations.emptyReferralsTitle)}</Text>
            <Text style={styles.subInfo}>{translate(translations.emptyReferralsInfo)}</Text>
          </View>
          <View style={styles.buttonsWrapper}>
            <ReferralShareButtons
              educator={educator}
              updateReferralsTermsAndConditions={handleUpdateReferralsTermsAndConditions}
            />
          </View>
        </>
      )
    }

    return referralsList.map(referralSection => {
      const sectionReferrals = referrals[referralSection]

      if (sectionReferrals.length === 0) return null

      return (
        <ReferralSection
          key={referralSection}
          listHeight={listHeight}
          listRef={listRef}
          section={referralSection as keyof typeof referralsStates}
          sectionReferrals={sectionReferrals}
        />
      )
    })
  }

  const isLoadingError = educatorError || referralsError

  const handleRefetch = () => {
    educatorError && educatorRefetch()
    referralsError && refetchReferrals()
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
        onContentSizeChange={(_, height) => setListHeight(height)}
        ref={listRef}
        showsVerticalScrollIndicator={false}
      >
        {isLoadingError && (
          <AlertCard
            additionalAction={{
              onPress: handleRefetch,
              title: capitalizeFirstLetter(translate(translations.retry)),
              isSubmitting: educatorLoading || referralsLoading,
            }}
            colorSchema={alertColorScheme.error}
            description={translate(translations.referralsErrorMessage)}
            style={styles.alert}
            title={translate(translations.somethingWentWrong)}
            variant={alertVariants.standard}
          />
        )}

        <View style={styles.moneyInfoWrapper}>
          <MoneyInfo
            amountOfMoney={givenMoney}
            animationVariant={animationVariant}
            isLoading={isLoading}
            moneyRise={givenMoneyRise}
            playAnimation={playAnimation}
            variant={moneyInfoVariant.given}
          />
          <MoneyInfo
            amountOfMoney={earnedMoney}
            animationVariant={animationVariant}
            isLoading={isLoading}
            moneyRise={earnedMoneyRise}
            playAnimation={playAnimation}
            variant={moneyInfoVariant.earned}
          />
        </View>
        {renderReferrals()}
      </ScrollView>
      {referralsListHasNextPage ? (
        <View style={styles.loadMoreButtonWrapper}>
          <ButtonNew
            isShort
            onPress={fetchMoreReferrals}
            style={styles.loadMoreButton}
            title={translate(translations.loadMore)}
            variant={buttonVariants.outlinedDefault}
          />
        </View>
      ) : null}
    </>
  )
}

export default ReferralsList
