import find from 'lodash/find'

import { profileTask, ProfileTaskStatus, ProfileTaskType } from 'src/scenes/Main/Profile/types'
import { RequiredTasks } from 'src/utils/types/navigationTypes'
import translations, { translate } from 'src/utils/translations/translations'
import { colorsNew } from 'src/styles'

export const getStatusInfo = (status: ProfileTaskStatus, timeToComplete: number | string) => {
  switch (status) {
    case 'completed':
      return {
        badgeColor: colorsNew.redesign.success.shades12p,
        text: translate(translations.profileTaskStatusCompleted),
        textColor: colorsNew.redesign.success.text,
      }
    case 'expired':
      return {
        badgeColor: colorsNew.redesign.warning.shades12p,
        text: translate(translations.profileTaskStatusExpired),
        textColor: colorsNew.redesign.warning.text,
      }
    case 'expiresSoon':
      return {
        badgeColor: colorsNew.redesign.warning.shades12p,
        text: translate(translations.profileTaskStatusExpiresSoon),
        textColor: colorsNew.redesign.warning.text,
      }
    case 'enabled':
      return {
        text: timeToComplete,
        textColor: colorsNew.redesign.text.secondary,
      }
  }
}

export const tasksScreensConfig: { [task in ProfileTaskType]: keyof RequiredTasks } = {
  addressVerification: 'AddressVerification',
  bookInterview: 'BookInterview',
  childCareCertificate: 'ChildCareCertificate',
  contract: 'Contract',
  contractAgreement: 'ContractAgreement',
  cvDocument: 'CvDocument',
  dbsCertificate: 'DbsCertificate',
  dbsDeclaration: 'DbsDeclaration',
  disqualificationDeclaration: 'DisqualificationDeclaration',
  emergencyContact: 'EmergencyContactDetails',
  employmentHistory: 'EmploymentHistory',
  highSchoolEducation: 'HighSchoolEducation',
  humlyAcademy: 'AcademyIntroduction',
  idVerification: 'IdVerification',
  interview: 'Interview',
  medicalQuestionnaire: 'MedicalQuestionnaire',
  niNumber: 'NiNumber',
  otherDocuments: 'OtherDocuments',
  payrollDetails: 'PayrollDetails',
  personalDetails: 'PersonalDetails',
  personalProfile: 'PersonalProfile',
  policies: 'Polices',
  policyAgreements: 'PolicyAgreements',
  proofOfEducations: 'ProofOfEducations',
  proofOfPoliceCheck: 'ProofOfPoliceCheck',
  proofOfPoliceReport: 'ProofOfPoliceReport',
  proofOfRightToWork: 'ProofOfRightToWork',
  qualifications: 'Qualifications',
  references: 'References',
  safeguardingDeclarations: 'Safeguarding',
  teacherReferenceNumber: 'TeacherReferenceNumber',
  teachingCertificates: 'TeachingCertificates',
  universityEducation: 'UniversityEducation',
  yotiStatus: 'DigitalRightToWork',
}

const lockedTaskInfo = (isGB: boolean) => [
  {
    description: translate(translations.lockedTask_completeInterview),
    modalInfo: translate(translations.lockedTask_completeInterviewModalInfo),
    tasks: isGB
      ? [profileTask.contractAgreement, profileTask.payrollDetails, profileTask.yotiStatus]
      : [profileTask.contract, profileTask.humlyAcademy, profileTask.policies],
  },
  {
    description: translate(translations.lockedTask_qualificationsAndExperience),
    modalInfo: translate(translations.lockedTask_qualificationsAndExperienceModalInfo),
    tasks: [profileTask.bookInterview, profileTask.interview].concat(
      //@ts-ignore
      isGB ? [profileTask.dbsCertificate, profileTask.dbsDeclaration] : [],
    ),
  },
]

const taskConfirmationModalInfo = (isGB: boolean) => [
  {
    modalContent: {
      info: translate(translations.taskConfirmationInfo_medicalQuestionnaire),
      title: translate(translations.taskConfirmationTitle_medicalQuestionnaire),
    },
    tasks: isGB ? [profileTask.medicalQuestionnaire] : [],
  },
]

export const getLockedTaskDescription = (task: ProfileTaskType, isGB: boolean) =>
  find(lockedTaskInfo(isGB), ({ tasks }: { tasks: ProfileTaskType[] }) => tasks.includes(task))?.description as string

export const getLockedTaskModalInfo = (task: ProfileTaskType, isGB: boolean) =>
  find(lockedTaskInfo(isGB), ({ tasks }: { tasks: ProfileTaskType[] }) => tasks.includes(task))?.modalInfo as string

export const getTaskConfirmationModalContent = (task: ProfileTaskType, isGB: boolean) =>
  find(taskConfirmationModalInfo(isGB), ({ tasks }: { tasks: ProfileTaskType[] }) => tasks.includes(task))?.modalContent
