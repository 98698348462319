import gql from 'graphql-tag'

export default gql`
  query ($refererId: String!, $first: Int!, $after: String) {
    referrals(filter: { refererId: $refererId }, first: $first, after: $after) {
      nodes {
        id
        bonusShareRatio
        locked
        refererPaidAt
        referredPaidAt
        referred {
          id
          appliedAt
          firstName
          lastName
          stateType
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`
