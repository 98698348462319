import gql from 'graphql-tag'

export default gql`
  query ($educatorId: ID!) {
    educator(id: $educatorId) {
      acceptedReferralsTermsAndConditions
      firstName
      id
      lastName
      referralCode
    }
  }
`
