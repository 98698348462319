import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { bugsnagViewBreadcrumb } from 'src/utils/bugsnag'
import { ComplianceStatusProvider } from 'src/context/ComplianceStatusContext'
import { isUserApplicant } from 'src/store/user/selectors'
import { MainTabNavigatorParamList } from 'src/utils/types/navigationTypes'
import { ScheduleNavigator } from './Schedule'
import { translate } from 'src/utils/translations/translations'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'
import { webTabNavigatorConfig } from 'src/utils/headerConfig/tabNavigatorConfig'
import ApolloWrapper from 'src/utils/ApolloWrapper'
import ClientDetails from './Jobs/ClientDetails'
import ExpressSummary from './Salary/ExpressSummary'
import Feedback from './Salary/Feedback'
import Inbox from './Inbox'
import Introduction from './Introduction'
import introductionHeaderConfig from 'src/utils/headerConfig/introductionHeaderConfig'
import Jobs from './Jobs'
import LoadingIndicator from 'src/components/LoadingIndicator'
import mainHeaderConfig from 'src/utils/headerConfig/mainHeaderConfig'
import Menu from './Menu'
import ProfileAndRecruitment from './Profile/ProfileAndRecruitment'
import ReferralAnnouncementModal from 'src/components/ReferralAnnouncementModal'
import Report from './Salary/Report/Report'
import SuccessAnimation from './Salary/SuccessAnimation'
import translations from 'src/utils/translations/translations'
import useChatMessage from 'src/hooks/useChatMessage'
import useInitUrlApp from 'src/hooks/useInitUrlApp'
import useNotificationsCount from 'src/hooks/useNotificationsCount'
import WebWrapper from 'src/components/WebWrapper'

const Stack = createStackNavigator()

export const Tab = createBottomTabNavigator<MainTabNavigatorParamList>()

const TabNavigator = () => {
  const isApplicant = useSelector(isUserApplicant)

  useChatMessage()
  useNotificationsCount()

  return (
    <Tab.Navigator {...webTabNavigatorConfig}>
      <Tab.Screen name="JobsNavigator" component={Jobs} options={{ title: translate('jobs') }} />
      {isApplicant ? (
        <Tab.Screen
          name="OnboardingView"
          component={ProfileAndRecruitment}
          options={{ title: translate('recruitment') }}
        />
      ) : (
        <Tab.Screen name="ScheduleNavigator" component={ScheduleNavigator} options={{ title: translate('schedule') }} />
      )}
      <Tab.Screen name="Notifications" component={Inbox} options={{ title: translate('notifications') }} />
      <Tab.Screen name="MenuNavigation" component={Menu} options={{ title: translate('menu') }} />
    </Tab.Navigator>
  )
}

const Main = () => {
  const [firstInit, setFirstInit] = useState(true)

  const { isLoading } = useInitUrlApp()
  const { featureFlags } = useFeatureFlagsContext()

  const appIsLoaded = !isLoading
  const appIsLoadedFirstTime = appIsLoaded && firstInit

  useEffect(() => {
    if (appIsLoadedFirstTime) {
      setFirstInit(false)
      bugsnagViewBreadcrumb('MainComponent App')
    }
  }, [appIsLoadedFirstTime])

  const storedModalVersion = localStorage.getItem('storedModalVersion')

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <ApolloWrapper>
      <ComplianceStatusProvider>
        {storedModalVersion !== '1' && featureFlags.release_refer_a_friend_240828 && <ReferralAnnouncementModal />}
        <WebWrapper shadowOffsetTop={64}>
          <Stack.Navigator>
            <Stack.Screen
              options={{
                headerShown: false,
                gestureEnabled: false,
                cardStyle: { backgroundColor: 'transparent' },
              }}
              component={TabNavigator}
              name="MainTabNavigator"
            />
            <Stack.Screen component={Introduction} name="Introduction" options={introductionHeaderConfig} />
            <Stack.Screen
              name="ClientDetails"
              component={ClientDetails}
              options={params => mainHeaderConfig(translations.school, params)}
            />
            <Stack.Screen
              name="ReportTime"
              component={Report}
              options={params => mainHeaderConfig(translations.completeYourTimesheet, params)}
            />
            <Stack.Screen name="SuccessAnimation" component={SuccessAnimation} options={{ headerShown: false }} />
            <Stack.Screen
              name="Feedback"
              component={Feedback}
              options={params => mainHeaderConfig(translations.feedbackTitle, params)}
            />
            <Stack.Screen
              name="ExpressSummary"
              component={ExpressSummary}
              options={params => mainHeaderConfig(translations.express, params)}
            />
          </Stack.Navigator>
        </WebWrapper>
      </ComplianceStatusProvider>
    </ApolloWrapper>
  )
}

export default Main
