import React, { PureComponent } from 'react'
import { Animated, StyleProp, Text, View, ViewStyle } from 'react-native'

import styles from './styles'

type SelectableTextProps = {
  animated: boolean
  children: React.ReactNode
  flexible: boolean
  style: StyleProp<ViewStyle>
}

export default class SelectableText extends PureComponent<SelectableTextProps> {
  render() {
    const { children, style, flexible, animated, ...textProps } = this.props
    const TextComponent = animated ? Animated.Text : Text

    return (
      <View style={flexible && styles.flexible}>
        <TextComponent selectable style={[global.isIOS && styles.text, style]} {...textProps}>
          {children}
        </TextComponent>
      </View>
    )
  }
}
