import gql from 'graphql-tag'

import { UserOnHoldRequestType } from './types'

export type { UserOnHoldRequestType }

export default gql`
  query OnHold($id: ID!) {
    user(id: $id) {
      id
      onHoldAt
      onHoldReason
    }
  }
`
