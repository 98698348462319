import ReferChip, { referChipVariants } from 'src/scenes/Main/ReferAFriendOrFoe/components/ReferChip'
import translations, { translate } from 'src/utils/translations/translations'

export const getReferralModalOptions = () => [
  {
    label: translate(translations.giveItAll),
    LabelComponent: ReferChip,
    labelComponentProps: {
      disablePressableBehaviour: true,
      variant: referChipVariants.give,
    },
    value: 'give',
  },
  {
    label: translate(translations.splitEvenly),
    LabelComponent: ReferChip,
    labelComponentProps: {
      disablePressableBehaviour: true,
      variant: referChipVariants.split,
    },
    value: 'split-50/50',
  },
  {
    label: translate(translations.takeItAll),
    LabelComponent: ReferChip,
    labelComponentProps: {
      disablePressableBehaviour: true,
      variant: referChipVariants.take,
    },
    value: 'take',
  },
]
