import React from 'react'
import { StyleSheet, Text, TextStyle, View } from 'react-native'
import { TextInput, TextInputProps } from 'react-native-paper'

import { colorsNew } from 'src/styles'
import { styles } from './styles'
import { TextInputSizeVariants } from './variants'
import { KeyboardTypeOptions } from 'react-native/Libraries/Components/TextInput/TextInput'

export type Props = {
  blurOnSubmit?: boolean
  disabled?: boolean
  errorMessage?: string
  forwardRef?: React.Ref<any>
  helperText?: string
  isError?: boolean
  isHigher?: boolean
  keyboardType?: KeyboardTypeOptions | undefined
  label?: string
  multiline?: boolean
  onBlur?: (args: any) => void
  onChangeText: (text: string) => void
  onFocus?: (args: any) => void
  onSubmitEditing?: () => void
  placeholder?: string
  required?: boolean
  returnKeyType?: string
  right?: React.JSX.Element | null
  secureTextEntry?: boolean
  size?: keyof typeof TextInputSizeVariants
  testID?: string
  title?: string
  titleStyles?: TextStyle
  value: string
  withBottomMargin?: boolean
} & TextInputProps

const outlineColor = ({ isError, disabled }: Pick<Props, 'isError' | 'disabled'>) => {
  if (isError) return colorsNew.redesign.error.main
  if (disabled) return colorsNew.redesign.other.outlinedBorder
  return colorsNew.redesign.secondary.shades30p
}

const activeOutlineColor = ({ isError, disabled }: Pick<Props, 'isError' | 'disabled'>) => {
  if (isError) return colorsNew.redesign.error.main
  if (disabled) return colorsNew.redesign.other.outlinedBorder
  return colorsNew.redesign.primary.main
}

const TextInputNew = ({
  disabled,
  errorMessage,
  forwardRef,
  helperText,
  isError,
  label,
  multiline,
  onBlur,
  onChangeText,
  required,
  size = TextInputSizeVariants.medium,
  testID = 'text-input-new',
  title,
  titleStyles = {},
  value,
  withBottomMargin = true,
  ...props
}: Props) => {
  const borderStyle = disabled ? 'dashed' : 'solid'

  const componentStyles = StyleSheet.flatten([
    styles.inputText,
    size === TextInputSizeVariants.small && styles.inputSmall,
    size === TextInputSizeVariants.big && styles.inputBig,
    size === TextInputSizeVariants.vBig && styles.inputVBig,
  ])

  const textColor = !disabled ? colorsNew.redesign.text.primary : colorsNew.redesign.text.disabled

  return (
    <View style={withBottomMargin ? styles.container : {}}>
      {title && <Text style={StyleSheet.flatten([styles.title, titleStyles])}>{title}</Text>}
      <TextInput
        activeOutlineColor={activeOutlineColor({ isError, disabled })}
        disabled={disabled}
        label={
          label ? (
            <Text style={styles.label}>
              {label}
              {required ? ' *' : ''}
            </Text>
          ) : undefined
        }
        mode="outlined"
        multiline={multiline}
        onBlur={onBlur}
        onChangeText={onChangeText}
        outlineColor={outlineColor({ isError, disabled })}
        outlineStyle={{ borderStyle }}
        ref={forwardRef}
        style={componentStyles}
        testID={testID}
        textColor={textColor}
        underlineColor="transparent"
        value={value}
        {...props}
      />
      {isError ? (
        <Text style={styles.error} testID={`${testID}-error`}>
          {errorMessage}
        </Text>
      ) : null}
      {helperText && !isError ? <Text style={styles.helper}>{helperText}</Text> : null}
    </View>
  )
}

export default TextInputNew
