import gql from 'graphql-tag'

import { BonusShareRatio } from 'src/graphql/Referrals/types'

export default gql`
  mutation Mutation($update: UpdateReferralBonusShareRatioDto!) {
    updateReferralBonusShareRatio(update: $update) {
      bonusShareRatio
    }
  }
`

export type UpdateReferralBonusShareRatioDto = {
  update: {
    id: string
    bonusShareRatio: BonusShareRatio
  }
}
