import React, { ReactNode } from 'react'
import { Text, TouchableOpacity } from 'react-native'

import Icon from 'src/components/Icon'
import styles from './styles'

type Props = {
  fieldLabel: ReactNode
  fieldValue: string | boolean | Date
  hasError: boolean
  onPress: () => void
}

const TouchableContent = ({ fieldValue, hasError, fieldLabel, onPress }: Props) => (
  <TouchableOpacity onPress={onPress} style={[styles.textInput, hasError && styles.errorInput]}>
    <Text style={fieldValue ? styles.fieldValue : styles.placeholder}>{fieldLabel}</Text>
    <Icon style={styles.arrow} name="arrow-down" size={14} />
  </TouchableOpacity>
)

export default TouchableContent
