import React, { useContext, useMemo, useState } from 'react'
import moment from 'moment'

import { ApplicationOfferDataType } from 'src/scenes/Main/Jobs/Details/components/ApplicationModal'
import { ComplianceStatusContext } from 'src/context/ComplianceStatusContext'
import { countryCodeSelector } from 'src/store/app/selectors'
import { getExpressBookingAppointment, isExpressUnbooked } from 'src/scenes/Main/Jobs/Details/helpers'
import { formatWorkPermitExpiredDate, getJobAlertDataBasedOnCompliance, isUserWorkPermitValid } from '../utils'
import { JobsNavigatorScreensProps, MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { useAppSelector } from 'src/hooks/reduxHooks'
import AcceptOverlay from './AcceptOverlay'
import ExpressAlert from './ExpressAlert/ExpressAlert'
import formatter from 'src/utils/formatter'
import JobDetails from 'src/scenes/Main/Jobs/Details/components/JobDetails'
import Loader from 'src/scenes/Main/Jobs/Details/components/Loader'
import translations, { translate } from 'src/utils/translations/translations'
import useTabBarOverlay from 'src/hooks/useTabBarOverlay'
import { getMixpanelBookingDay } from 'src/scenes/Main/Jobs/Details/ShortTermOfferDetails/Booking/utils'
import { getUserWorkPermitInfo } from 'src/store/user/selectors'
import { isSESelector } from 'src/store/app/selectors'
import { TranslationSVT } from 'src/utils/translations/i18nTypes'

export type ExpressNavigationType =
  | JobsNavigatorScreensProps<'ExpressSub'>
  | MenuNavigatorScreensProps<'ExpressSubMenu'>

const Express = ({ route }: ExpressNavigationType) => {
  const { item } = route.params
  const { isUserCompliant } = useContext(ComplianceStatusContext)
  const { mixpanel } = useContext(MixpanelContext)
  const isSE = useAppSelector(isSESelector)
  const { workPermitExpiresOn, workPermitRequired } = useAppSelector(getUserWorkPermitInfo)

  const [isUpdating, setIsUpdating] = useState(false)
  const [isApplicationCompleted, setIsApplicationCompleted] = useState(false)

  const showOverlay = !item.appointedAt && !isApplicationCompleted

  const onOpen = () =>
    mixpanel?.track('Express Details', {
      appointedBy: getExpressBookingAppointment(item.appointedAt),
      date: getMixpanelBookingDay(item.date),
      expressId: item?.id,
      status: item.status,
    })

  const countryCode = useAppSelector(countryCodeSelector)

  const screenTitle = showOverlay
    ? translate(translations.jobOfferHeaderExpressAvailable)
    : translate(translations.jobOfferHeaderConfirmedExpress)
  const { goBack } = useTabBarOverlay(showOverlay, screenTitle)

  const jobHeader = useMemo(
    () => ({
      details: translate((translations as TranslationSVT).expressHeaderDetails),
      hours: translate((translations as TranslationSVT).expressHours),
      startDate: item?.date || '',
      title: moment(item?.date).format('dddd'),
    }),
    [item],
  )

  const workPermitExpiresOnFormatted = formatWorkPermitExpiredDate(workPermitExpiresOn)

  const isUserWorkPermitInvalid = isSE
    ? false
    : !isUserWorkPermitValid({
        jobLatestDate: item?.date,
        workPermitExpiresOn,
        workPermitRequired,
      })

  const userComplianceAlertData = useMemo(
    () =>
      getJobAlertDataBasedOnCompliance({
        isUserCompliant,
        isUserWorkPermitInvalid,
        workPermitExpiresOn: workPermitExpiresOnFormatted,
      }),
    [isUserCompliant, isUserWorkPermitInvalid, workPermitExpiresOnFormatted],
  )

  const payRate = item?.salaryRate
    ? formatter.getSalarySubtitle({
        countryCode,
        holidayRate: item?.holidayPayRate,
        salary: item?.salaryRate,
        scheduleType: 'hourly',
      })
    : undefined

  const jobInformation = item?.description || ''
  const jobCompetences = item?.expressBookingPool.competences
  const jobLevel = item?.expressBookingPool.levels
  const jobTitle = item?.expressBookingPool.title || ''

  const jobConfirmationData: ApplicationOfferDataType = {
    date: formatter.fullDay(item.date),
    hours: translate((translations as TranslationSVT).expressHours),
    title: item.expressBookingPool.title,
    time: '6:15 - ?',
    type: 'express',
  }

  const isUnbooked = isExpressUnbooked(item.date)
  const isUserNotAvailable = item.status !== 'available'

  return (
    <>
      {isUpdating ? <Loader /> : null}

      <JobDetails
        competences={isSE ? undefined : jobCompetences}
        jobHeader={jobHeader}
        jobInformation={jobInformation}
        jobLevel={jobLevel}
        jobTitle={jobTitle}
        onOpen={onOpen}
        payRate={payRate}
        showMask={isUnbooked || isUserNotAvailable}
      >
        <ExpressAlert
          isUserNotAvailable={isUserNotAvailable}
          showConfirmationAlert={isApplicationCompleted}
          showOfferAlert={showOverlay}
          showUnbookedAlert={isUnbooked}
          userComplianceAlertData={userComplianceAlertData}
        />
      </JobDetails>

      {showOverlay ? (
        <AcceptOverlay
          expressDate={item.date}
          expressPoolId={item.expressBookingPool.id}
          goBack={goBack}
          isUpdating={isUpdating}
          isUserWorkPermitInvalid={isUserWorkPermitInvalid}
          jobConfirmationData={jobConfirmationData}
          setIsApplicationCompleted={setIsApplicationCompleted}
          setIsUpdating={setIsUpdating}
          workPermitExpiresOn={workPermitExpiresOnFormatted}
        />
      ) : null}
    </>
  )
}

export default Express
