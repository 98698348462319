import { useCallback, useMemo } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import some from 'lodash/some'

import AllProposals, { ProposalsRequestTypes } from 'src/graphql/Proposal'
import BookingsList from 'src/graphql/Booking/BookingsList'
import ExpressBookings, { ExpressBookingsRequestTypes } from 'src/graphql/ExpressBookings'
import ExpressBookingPools from 'src/graphql/ExpressBookingPools'
import OrderList from 'src/graphql/Order/OrderList'
import { BookingsListTypes } from 'src/graphql/Booking/types'
import { JobsNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import { jobAppointmentType } from 'src/global/constants'
import { OrdersListTypes } from 'src/graphql/Order/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import { getJobsOffersSections } from './utils'
import { isGBSelector } from 'src/store/app/selectors'
import { isUserApplicant } from 'src/store/user/selectors'
import { ExpressBookingPoolsRequestTypes } from 'src/graphql/ExpressBookingPools/types'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'

const useJobsOffersList = () => {
  const isApplicant = useAppSelector(isUserApplicant)
  const isGB = useAppSelector(isGBSelector)
  const userId = useAppSelector(state => state.user.id) as string
  const userMayExpressBookings = useAppSelector(state => state.user.attributes?.mayExpressBookings)
  const regionId = useAppSelector(state => state.user.attributes?.regionId)
  const isConnected = useAppSelector(state => state.app.isConnected)

  const navigation = useNavigation<JobsNavigatorScreensProps<'Requests'>['navigation']>()

  const { featureFlags } = useFeatureFlagsContext()

  const refreshFunction = () => {
    bugsnagActionBreadcrumb('refresh', { isConnected })
    if (isConnected) {
      if (isApplicant) {
        return Promise.all([proposals.refetch()])
      }

      return Promise.all([
        bookings.refetch(),
        expressBookingPools.refetch(),
        expressBookings.refetch(),
        orders.refetch(),
        proposals.refetch(),
      ])
    }

    return Promise.resolve()
  }

  const needsBookingConfirmation = useCallback(
    (data: BookingsListTypes) => {
      const jobData = data?.bookings?.nodes
      const unconfirmed = jobData.filter(booking => booking.appointmentUnconfirmed)
      const unconfirmedOffer = unconfirmed[0]

      const isExpressBooking =
        unconfirmed.length > 0 &&
        unconfirmedOffer.appointmentType === jobAppointmentType.direct &&
        unconfirmedOffer?.studentId === userId

      if (isExpressBooking) {
        bugsnagActionBreadcrumb('got to UnconfirmedExpress booking', { id: unconfirmedOffer.id })
        setTimeout(() => navigation.isFocused() && navigation.navigate('Booking', { id: unconfirmedOffer.id }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId],
  )

  const needsOrderConfirmation = useCallback(
    (data: OrdersListTypes) => {
      const jobData = data?.orders?.nodes
      const unconfirmed = jobData.filter(order => order.appointmentUnconfirmed)
      const unconfirmedOffer = unconfirmed[0]

      const isExpressBooking =
        unconfirmed.length > 0 &&
        unconfirmedOffer.appointmentType === jobAppointmentType.direct &&
        unconfirmedOffer?.student.id === userId

      if (isExpressBooking) {
        bugsnagActionBreadcrumb('got to UnconfirmedExpress order', { id: unconfirmedOffer.id })
        setTimeout(() => navigation.isFocused() && navigation.navigate('Order', { id: unconfirmedOffer.id }))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId],
  )

  const orders = useQuery<OrdersListTypes>(OrderList, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-and-network',
    skip: isApplicant,
    variables: {
      appliedByMe: false,
      matching: false,
      region: regionId,
    },

    onCompleted: needsOrderConfirmation,
  })

  const bookings = useQuery<BookingsListTypes>(BookingsList, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'cache-and-network',
    skip: isApplicant,
    variables: {
      appliedByMe: false,
      bookingType: 'unappointed',
      region: regionId,
      skipCancelled: true,
    },
    onCompleted: needsBookingConfirmation,
  })

  const expressBookings = useQuery<ExpressBookingsRequestTypes>(ExpressBookings, {
    fetchPolicy: 'cache-and-network',
    skip: isApplicant,
    variables: {
      student: userId,
      include_appointed: false,
      include_available: true,
      only_matching: false,
    },
  })

  const expressBookingPools = useQuery<ExpressBookingPoolsRequestTypes>(ExpressBookingPools, {
    fetchPolicy: 'cache-and-network',
    skip: isApplicant,
    variables: {
      userRegion: regionId,
    },
  })

  const proposals = useQuery<ProposalsRequestTypes>(AllProposals, {
    fetchPolicy: 'cache-and-network',
    variables: {
      appliedByMe: false,
      matching: true,
      region: regionId,
    },
  })

  const isLoading = some([
    expressBookings.loading,
    expressBookingPools.loading,
    orders.loading,
    bookings.loading,
    proposals.loading,
  ])
  const isExpressAndLongTermLoading = useMemo(
    () => some([expressBookingPools.loading, expressBookings.loading, proposals.loading]),
    [expressBookingPools.loading, expressBookings.loading, proposals.loading],
  )

  const bookingsList = useMemo(() => bookings.data?.bookings?.nodes || [], [bookings.data])
  const ordersList = useMemo(() => orders.data?.orders?.nodes || [], [orders.data])
  const expressesList = useMemo(() => expressBookings?.data?.expressBookings?.nodes || [], [expressBookings.data])

  const expressBookingPoolsList = useMemo(
    () => expressBookingPools?.data?.expressBookingPools?.nodes || [],
    [expressBookingPools.data],
  )

  const isExpressExists = expressBookingPoolsList?.length >= 1
  const isExpressShouldBeVisible =
    isExpressExists && userMayExpressBookings && (isGB ? featureFlags.expressInTheUK : true)

  const shortTermOffers = useMemo(() => {
    return getJobsOffersSections([...bookingsList, ...ordersList], userId)
  }, [bookingsList, ordersList, userId])

  const proposalsList = useMemo(() => proposals?.data?.proposals?.nodes || [], [proposals])

  return {
    expressCount: expressesList.length,
    isExpressAndLongTermLoading,
    isExpressShouldBeVisible,
    isLoading,
    longTermCount: proposalsList.length,
    refreshFunction,
    shortTermOffers,
  }
}

export default useJobsOffersList
