import gql from 'graphql-tag'

export default gql`
  query ($refererId: String!) {
    referralStats(filter: { refererId: $refererId }) {
      earned
      earnedSinceLastMonth
      given
      givenSinceLastMonth
    }
  }
`
