import { useMutation, useQuery } from '@apollo/client'

import { useAppSelector } from 'src/hooks/reduxHooks'
import {
  EducatorQuery,
  EducatorRequestType,
  UpdateReferralsTermsAndConditions,
  updateReferralsTermsAndConditionsInput,
} from 'src/graphql/Educator'

const useEducatorData = () => {
  const educatorId = useAppSelector(state => state.user.id)

  const { data, loading, error, refetch } = useQuery<EducatorRequestType>(EducatorQuery, {
    variables: {
      educatorId,
    },
    fetchPolicy: 'network-only',
  })
  const educator = data?.educator

  const [updateReferralsTermsAndConditions] = useMutation<updateReferralsTermsAndConditionsInput>(
    UpdateReferralsTermsAndConditions,
  )

  return {
    educator,
    error,
    loading,
    refetch,
    updateReferralsTermsAndConditions,
  }
}

export default useEducatorData
