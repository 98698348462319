import React, { useState } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { colorsNew } from 'src/styles'
import { EditIcon } from 'src/icons'
import { ReferChipVariant } from './types'
import { referChipVariants } from './variants'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  disabled?: boolean
  disablePressableBehaviour?: boolean
  onPress?: () => void
  variant: ReferChipVariant
}

const ReferChip = ({ disabled, disablePressableBehaviour, onPress, variant = referChipVariants.split }: Props) => {
  const [isPressed, setIsPressed] = useState(false)
  const [isHover, setIsHover] = useState(false)

  const shouldHandleEvents = !disablePressableBehaviour && !disabled
  const isShouldBePressed = (action: () => void) => shouldHandleEvents && action()

  const handleOnPressIn = () => isShouldBePressed(() => setIsPressed(true))
  const handleOnPressOut = () => isShouldBePressed(() => setIsPressed(false))

  const handleOnHoverIn = () => isShouldBePressed(() => setIsHover(true))
  const handleOnHoverOut = () => isShouldBePressed(() => setIsHover(false))

  const mappedText = {
    'split-50/50': translate(translations.splitEvenly),
    give: translate(translations.giveItAll),
    take: translate(translations.takeItAll),
  }

  return (
    <Pressable
      onHoverIn={handleOnHoverIn}
      onHoverOut={handleOnHoverOut}
      onPress={onPress}
      onPressIn={handleOnPressIn}
      onPressOut={handleOnPressOut}
      style={StyleSheet.flatten([
        styles.container,
        styles[variant],
        disabled ? styles.disabledContainer : {},
        isHover && onPress ? styles[variant].hover : {},
        isPressed && onPress ? styles[variant].press : {},
      ])}
    >
      <Text style={StyleSheet.flatten([styles.text, disabled ? styles.disabledText : {}])}>{mappedText[variant]}</Text>
      {onPress && shouldHandleEvents ? (
        <View>
          <EditIcon size={14} fill={colorsNew.redesign.action.active} />
        </View>
      ) : null}
    </Pressable>
  )
}

export default ReferChip
