export const bonusShareRatioToVariant = {
  TAKE_ALL: 'take',
  FIFTY_FIFTY: 'split-50/50',
  NOTHING: 'give',
} as const

export const referralsStates = {
  educatorAccepted: 'educatorAccepted',
  educatorInRecruitment: 'educatorInRecruitment',
  educatorLeftHumly: 'educatorLeftHumly',
  paidOut: 'paidOut',
}
