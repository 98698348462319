import { useMutation, useQuery } from '@apollo/client'

import { bonusShareRatioToVariant, referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import { ReferralFormatted } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/types'
import { useAppSelector } from 'src/hooks/reduxHooks'
import formatter from 'src/utils/formatter'
import {
  ReferralsListQuery,
  ReferralsListRequestType,
  ReferralStatsQuery,
  ReferralStatsRequestType,
  UpdateReferralBonusMutation,
  UpdateReferralBonusShareRatioDto,
} from 'src/graphql/Referrals'

const useReferralsData = ({ skipFetchingData = false }) => {
  const educatorId = useAppSelector(state => state.user.id)

  const {
    data: referralsListData,
    error: referralsListError,
    fetchMore: referralsListFetchMore,
    loading: referralsListLoading,
    refetch: referralsListRefetch,
  } = useQuery<ReferralsListRequestType>(ReferralsListQuery, {
    variables: {
      first: 50,
      refererId: educatorId,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: skipFetchingData,
  })

  const handleQueryUpdate = (
    prev: ReferralsListRequestType,
    { fetchMoreResult }: { fetchMoreResult: ReferralsListRequestType },
  ) => {
    if (!fetchMoreResult) return prev

    return {
      referrals: {
        nodes: [...prev.referrals.nodes, ...fetchMoreResult.referrals.nodes],
        pageInfo: fetchMoreResult.referrals.pageInfo,
      },
    }
  }

  const fetchMoreReferrals = () =>
    referralsListFetchMore({
      variables: { after: referralsListEndCursor },
      updateQuery: handleQueryUpdate,
    })

  const allReferrals = referralsListData?.referrals?.nodes || []
  const referralsListEndCursor = referralsListData?.referrals?.pageInfo?.endCursor
  const referralsListHasNextPage = referralsListData?.referrals?.pageInfo?.hasNextPage

  const {
    data: referralStatsData,
    error: referralStatsError,
    loading: referralStatsLoading,
    refetch: referralStatsRefetch,
  } = useQuery<ReferralStatsRequestType>(ReferralStatsQuery, {
    variables: {
      refererId: educatorId,
    },
    fetchPolicy: 'network-only',
    skip: skipFetchingData,
  })

  const [updateReferralBonusShare] = useMutation<UpdateReferralBonusShareRatioDto>(UpdateReferralBonusMutation)

  const refetchAllData = () => {
    referralsListRefetch()
    referralStatsRefetch()
  }

  const refetchOnError = () => {
    referralsListError && referralsListRefetch()
    referralStatsError && referralStatsRefetch()
  }

  const referrals = allReferrals.reduce(
    (result, referral) => {
      const refererPaidAt = referral.refererPaidAt
      const referredPaidAt = referral.referredPaidAt
      const stateType = referral.referred.stateType

      const referralFormatted = {
        id: referral.id,
        applicantName: `${referral?.referred?.firstName} ${referral?.referred?.lastName}`,
        appliedAt: formatter.shortDate(referral.referred.appliedAt),
        locked: referral.locked || false,
        refererPaidAt: referral.refererPaidAt ? formatter.shortDate(referral.refererPaidAt) : '',
        referredPaidAt: referral.referredPaidAt ? formatter.shortDate(referral.referredPaidAt) : '',
        variant: bonusShareRatioToVariant[referral.bonusShareRatio || 'FIFTY_FIFTY'],
      }

      if (referredPaidAt !== null || refererPaidAt !== null) result[referralsStates.paidOut].push(referralFormatted)
      else {
        if (stateType === 'HIRED') result[referralsStates.educatorAccepted].push(referralFormatted)
        else if (stateType === 'APPLICANT') result[referralsStates.educatorInRecruitment].push(referralFormatted)
        else if (stateType === 'INACTIVE') result[referralsStates.educatorLeftHumly].push(referralFormatted)
      }

      return result
    },
    {
      [referralsStates.paidOut]: [] as ReferralFormatted[] | [],
      [referralsStates.educatorAccepted]: [] as ReferralFormatted[] | [],
      [referralsStates.educatorInRecruitment]: [] as ReferralFormatted[] | [],
      [referralsStates.educatorLeftHumly]: [] as ReferralFormatted[] | [],
    },
  )

  const referralStats = referralStatsData?.referralStats

  const earnedMoney = referralStats?.earned || 0
  const earnedMoneyRise = referralStats?.earnedSinceLastMonth || 0
  const givenMoney = referralStats?.given || 0
  const givenMoneyRise = referralStats?.givenSinceLastMonth || 0

  const moneyInfo = {
    earnedMoney,
    earnedMoneyRise,
    givenMoney,
    givenMoneyRise,
  }

  return {
    error: referralStatsError || referralsListError,
    fetchMoreReferrals,
    isNoReferrals: allReferrals.length === 0,
    loading: referralStatsLoading || referralsListLoading,
    moneyInfo,
    referrals,
    referralsListHasNextPage,
    refetchAllData,
    refetchOnError,
    updateReferralBonusShare,
  }
}

export default useReferralsData
