import React, { useEffect, useState } from 'react'

import { useAppSelector } from 'src/hooks/reduxHooks'
import AnnouncementModal from 'src/components/AnnouncementModal'

const ReferralAnnouncementModal = () => {
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false)
  const isConnected = useAppSelector(state => state.app.isConnected)

  const closeAnnouncementModal = () => {
    setShowAnnouncementModal(false)
    localStorage.setItem('storedModalVersion', '1')
  }

  const checkPageVisit = async () => {
    const storedModalVersion = localStorage.getItem('storedModalVersion')

    if (storedModalVersion !== '1') {
      setShowAnnouncementModal(true)
    }
  }

  useEffect(() => {
    if (isConnected) checkPageVisit()
  }, [isConnected])

  return <AnnouncementModal closeAnnouncementModal={closeAnnouncementModal} showModal={showAnnouncementModal} />
}

export default ReferralAnnouncementModal
