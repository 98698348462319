import React, { RefObject, useEffect, useRef, useState } from 'react'
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native'

import { ChevronRightIcon } from 'src/icons'
import { getReferralSectionInfo } from 'src/scenes/Main/ReferAFriendOrFoe//utils'
import { ReferralFormatted } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/types'
import { referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import ReferralRow from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/components/ReferralRow'
import styles from './styles'

type TProps = {
  listHeight: number
  listRef: RefObject<ScrollView>
  section: keyof typeof referralsStates
  sectionReferrals: ReferralFormatted[]
}

const ReferralSection = ({ listHeight, listRef, section, sectionReferrals }: TProps) => {
  const [isSectionOpen, setIsSectionOpen] = useState(false)
  const scrollTimer = useRef<NodeJS.Timeout>()

  useEffect(() => {
    return () => scrollTimer.current && clearTimeout(scrollTimer.current)
  }, [])

  const toggleIsSectionOpen = () => {
    setIsSectionOpen(prevState => !prevState)
    scrollTimer.current = setTimeout(() => {
      !isSectionOpen && listRef.current?.scrollTo({ x: 0, y: listHeight - 40, animated: true })
    }, 50)
  }

  const renderReferrals = () => (
    <View style={styles.referralsWrapper}>
      {sectionReferrals.map(referral => (
        <ReferralRow key={referral.id} referral={referral} section={section} />
      ))}
    </View>
  )

  const renderLastSection = () => (
    <>
      <Pressable
        onPress={toggleIsSectionOpen}
        style={({ pressed }) =>
          StyleSheet.flatten([
            pressed ? styles.touchPressed : styles.touchUnpressed,
            !isSectionOpen ? styles.spaceBottom : {},
          ])
        }
        testID={`referralRow-${section}-open`}
      >
        <View style={styles.titleIconWrapper}>
          <Text style={styles.title}>{title}</Text>
          <ChevronRightIcon
            fill={styles.iconColor.color}
            size={24}
            style={isSectionOpen ? styles.iconOpen : styles.iconClosed}
          />
        </View>
      </Pressable>
      {isSectionOpen ? renderReferrals() : null}
    </>
  )

  const isLastSection = section === referralsStates.educatorLeftHumly

  const { title, info } = getReferralSectionInfo()[section]

  return (
    <View style={styles.container}>
      {isLastSection ? (
        renderLastSection()
      ) : (
        <>
          <Text style={styles.title}>{title}</Text>
          {info ? <Text style={styles.info}>{info}</Text> : null}
          {renderReferrals()}
          <View style={styles.divider} />
        </>
      )}
    </View>
  )
}

export default ReferralSection
