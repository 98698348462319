import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { Error, Label, TouchableContent } from 'src/components/Form/Common'
import TextInputNew, { TextInputSizeVariants } from 'src/components/Form/TextInputNew'
import formatter from 'src/utils/formatter'
import { PropsT } from './types'
import './stylesCss.css'

const InputDate = ({
  allowEmpty,
  disabled,
  field: { name, value },
  form: { errors, touched, setFieldTouched, setFieldValue },
  label,
  maximumDate = new Date(),
  placeholder,
  required,
  useNew,
  validateOnMount,
}: PropsT) => {
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState(value ? new Date(value) : new Date())
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  return (
    <>
      {useNew ? (
        <TextInputNew
          disabled={disabled}
          errorMessage={errors[name]}
          isError={!isEmpty(errors.birthDate) && (touched.birthDate || validateOnMount)}
          label={label}
          onFocus={() => {
            setFieldTouched(name, true)
            setIsOpen(true)
          }}
          onChangeText={text => setFieldValue(name, text)}
          required={required}
          size={TextInputSizeVariants.medium}
          value={allowEmpty && !value ? '' : formatter.apiFormatWithoutTimezone(value)}
        />
      ) : (
        <>
          {label && <Label label={label} required={required} />}
          <TouchableContent
            fieldValue={value}
            fieldLabel={value ? formatter.shortDate(startDate) : placeholder}
            hasError={hasError}
            onPress={() => setIsOpen(true)}
          />
          <Error errors={isTouched ? errors : {}} name={name} />
        </>
      )}
      {isOpen ? (
        <DatePicker
          inline={true}
          placeholderText={placeholder}
          selected={startDate}
          dateFormat="dd-MM-yyyy"
          maxDate={maximumDate}
          onChange={(date: Date) => {
            setIsOpen(false)
            setFieldTouched(name)
            setStartDate(date)
            setFieldValue(name, formatter.apiFormat(date))
          }}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
      ) : null}
    </>
  )
}

export default InputDate
