import React, { useRef, useState } from 'react'
import { ScrollView } from 'react-native'

import MedicalQuestionnaireInfo from './MedicalQuestionnaireInfo'
import MedicalQuestionnaireForm from './MedicalQuestionnaireForm'
import StatusInfo from '../components/StatusInfo/StatusInfo'
import { ProfileAndRecruitmentScreensProps } from 'src/utils/types/navigationTypes'
import { useBugsnagView } from 'src/utils/bugsnag'
import styles from './styles'
import globalStyles from 'src/global/globalStyles'

type Props = ProfileAndRecruitmentScreensProps<'MedicalQuestionnaire'>

const MedicalQuestionnaire = ({ route }: Props) => {
  const { expiresAt, status, task, timeToComplete } = route.params
  const [showForm, setShowForm] = useState(false)

  useBugsnagView('RequiredTask - MedicalQuestionnaire')

  const scrollRef = useRef<ScrollView>()

  const scrollToTop = () => scrollRef.current?.scrollTo({ x: 0, y: 0, animated: true })

  const onFormOpen = () => {
    setShowForm(true)
    scrollToTop()
  }

  const onFormClose = () => {
    setShowForm(false)
    scrollToTop()
  }

  return (
    <ScrollView
      contentContainerStyle={{ ...styles.container, ...globalStyles.containerSize }}
      showsVerticalScrollIndicator={false}
      ref={scrollRef}
      testID="scrollView"
    >
      <StatusInfo timeToComplete={timeToComplete} status={status} />
      {showForm ? (
        <MedicalQuestionnaireForm closeForm={onFormClose} task={task} />
      ) : (
        <MedicalQuestionnaireInfo showForm={onFormOpen} expireDate={expiresAt} />
      )}
    </ScrollView>
  )
}

export default MedicalQuestionnaire
