import React from 'react'
import PropTypes from 'prop-types'
import { StyleProp, View, ViewStyle } from 'react-native'

import Checkbox from 'src/components/Form/Checkbox'

import styles from './styles'

type RowWithCheckBoxProps = {
  disabled?: boolean
  isChecked: boolean
  onValueChange: (value: boolean) => void
  style?: StyleProp<ViewStyle>
  testID?: string
  title: string
}

const RowWithCheckBox = ({ style, isChecked, disabled, onValueChange, title, testID }: RowWithCheckBoxProps) => (
  <View style={[styles.checkBoxRow, style]}>
    <Checkbox
      customLabelStyles={styles.label}
      disabled={disabled}
      label={title}
      onPress={() => onValueChange(!isChecked)}
      selected={isChecked}
      testID={testID}
    />
  </View>
)

export default RowWithCheckBox
