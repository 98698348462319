import React, { useState } from 'react'
import { Platform, Pressable, ScrollView, Text, View } from 'react-native'

import { DotTextRow } from 'src/components/DotTextRow'
import ButtonNew, { buttonVariants } from 'src/components/Buttons/ButtonNew'
import Checkbox from 'src/components/Form/Checkbox'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

const isWeb = Platform.OS === 'web'

type Props = {
  handleClose: () => void
  handleSubmit: () => void
  hasAcceptedTerms?: boolean
  showModal: boolean
}

const TermsAndConditionsModal = ({ handleClose, handleSubmit, hasAcceptedTerms, showModal }: Props) => {
  const [termsAndConditionsIsSelected, setTermsAndConditionsIsSelected] = useState(false)

  const ContentWrapper = isWeb ? View : Pressable

  const handleCloseAndResetState = () => {
    setTermsAndConditionsIsSelected(false)
    handleClose()
  }

  return (
    <NewModalWrapper
      testID="termsAndConditionsModal"
      backdropOpacity={0.5}
      containerStyle={styles.modalContent}
      isVisible={showModal}
      onBackdropPress={handleCloseAndResetState}
      onSwipeComplete={handleCloseAndResetState}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <View style={styles.divider} />
        <Text style={styles.title}>{translate(translations.fullBonusTermsAndConditions)}</Text>
        <ScrollView scrollEnabled showsVerticalScrollIndicator>
          <ContentWrapper>
            <View style={styles.innerContainer}>
              <Text style={styles.text}>{translate(translations.referralBonusNeedsToMeetConditions)}</Text>
              <View>
                <DotTextRow text={translate(translations.workAtleastOnce)} containerStyle={styles.dotTextContainer} />
                <DotTextRow text={translate(translations.youCanStillInvite)} containerStyle={styles.dotTextContainer} />
                <DotTextRow text={translate(translations.youMustBeHired)} containerStyle={styles.dotTextContainer} />
              </View>
              <Text style={styles.text}>{translate(translations.bonusAmountCanVary)}</Text>
              <Text style={styles.text}>
                <Text>{translate(translations.allAmountsShown)} </Text>
                <Text style={styles.beforeTaxText}>{`${translate(translations.beforeTax)}.`} </Text>
                <Text>{translate(translations.theBonusIsTaxed)}</Text>
              </Text>
              <Text style={styles.text}>{translate(translations.youDecideYourCut)}</Text>
              <Text style={styles.infoText}>{translate(translations.anyQuestionsAboutTheTermsContactUs)}</Text>

              {!hasAcceptedTerms ? (
                <View>
                  <Checkbox
                    testID="termsAndConditionsCheckbox"
                    color="light"
                    label={translate(translations.agreementTermsAndConditions)}
                    onPress={() => setTermsAndConditionsIsSelected((prev: boolean) => !prev)}
                    selected={termsAndConditionsIsSelected}
                  />

                  <View style={[styles.buttonSpace, isWeb && styles.buttonRow]}>
                    {isWeb && (
                      <ButtonNew
                        isLong
                        onPress={handleCloseAndResetState}
                        style={styles.button}
                        title={translate(translations.close)}
                        variant={buttonVariants.outlinedDefault}
                      />
                    )}
                    <ButtonNew
                      disabled={!termsAndConditionsIsSelected}
                      isLong
                      onPress={handleSubmit}
                      style={styles.button}
                      title={translate(translations.continue)}
                      variant={buttonVariants.containedDefault}
                    />
                  </View>
                </View>
              ) : (
                <ButtonNew
                  isLong
                  onPress={handleCloseAndResetState}
                  title={translate(translations.close)}
                  variant={buttonVariants.containedDefault}
                />
              )}
            </View>
          </ContentWrapper>
        </ScrollView>
      </View>
    </NewModalWrapper>
  )
}

export default TermsAndConditionsModal
