import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const styles = StyleSheet.create({
  alert: {
    marginVertical: paddingNew.xs,
  },
  buttonsWrapper: { marginHorizontal: paddingNew.md },
  container: {
    marginBottom: paddingNew.base,
    paddingHorizontal: paddingNew.sm,
  },
  emptyViewWrapper: {
    alignItems: 'center',
    marginTop: paddingNew.base,
  },
  info: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
    marginBottom: paddingNew.xs,
    marginTop: paddingNew.base,
    textAlign: 'center',
  },
  loadMoreButton: {
    alignSelf: 'center',
  },
  loadMoreButtonWrapper: {
    alignItems: 'center',
    backgroundColor: colorsNew.white,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    paddingVertical: paddingNew.sm,
    position: 'relative',
    right: 0,
  },
  moneyInfoWrapper: {
    flexDirection: 'row',
    gap: paddingNew.base,
    marginBottom: paddingNew.base,
  },
  subInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    marginBottom: 20,
    textAlign: 'center',
  },
})

export default styles
