import React, { useContext } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { capitalizeFirstLetter } from 'src/global/utils'
import { InfoOutlinedIcon, PersonRoundedIcon } from 'src/icons'
import { MenuNavigatorScreensProps } from 'src/utils/types/navigationTypes'
import { MixpanelContext } from 'src/context/MixpanelContext'
import { ReferralShareButtons } from '../components'
import { useBugsnagView } from 'src/utils/bugsnag'
import AlertCard, { alertColorScheme, alertVariants } from 'src/components/AlertCard'
import FriendOrFoeFullBodyIcon from 'src/icons/redesign/friendOrFoeFullBody'
import globalStyles from 'src/global/globalStyles'
import RedirectRow from 'src/components/RedirectRow'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useEducatorData from 'src/hooks/useEducatorData'
import { useAppSelector } from 'src/hooks/reduxHooks'

const Referrals = ({ navigation }: MenuNavigatorScreensProps<'Referrals'>) => {
  useBugsnagView('ReferAFriendOrFoe - Referrals')

  const { educator, error, loading, refetch, updateReferralsTermsAndConditions } = useEducatorData()

  const educatorId = useAppSelector(state => state.user.id)

  const { mixpanel } = useContext(MixpanelContext)

  const goToHowItWorks = () => {
    mixpanel?.track('ReferAFriendOrFoe - How It Works', { action: 'open' })
    navigation.navigate('HowItWorks')
  }
  const goToReferralsList = () => {
    mixpanel?.track('ReferAFriendOrFoe - ReferralsList', { action: 'open' })
    navigation.navigate('ReferralsList')
  }
  const handleRefetch = () => {
    refetch()
  }

  const handleUpdateReferralsTermsAndConditions = async () => {
    await updateReferralsTermsAndConditions({ variables: { update: { educatorId, value: true } } })
    await refetch()
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      contentContainerStyle={globalStyles.containerSize}
      testID="scrollView"
    >
      {error && (
        <AlertCard
          additionalAction={{
            onPress: handleRefetch,
            title: capitalizeFirstLetter(translate(translations.retry)),
            isSubmitting: loading,
          }}
          colorSchema={alertColorScheme.error}
          description={translate(translations.referralsErrorMessage)}
          style={styles.alert}
          title={translate(translations.somethingWentWrong)}
          variant={alertVariants.standard}
        />
      )}

      <View style={styles.headerWrapper}>
        <FriendOrFoeFullBodyIcon />
        <Text style={styles.info}>{translate(translations.referralsInfo)}</Text>
        <Text style={styles.subInfo}>{translate(translations.referralsSubInfo)}</Text>
      </View>
      <View style={styles.buttonsWrapper}>
        <ReferralShareButtons
          educator={educator}
          updateReferralsTermsAndConditions={handleUpdateReferralsTermsAndConditions}
        />
      </View>

      <View style={styles.divider} />

      <View style={styles.redirectsRowsWrapper}>
        <RedirectRow
          Icon={PersonRoundedIcon}
          onPress={goToReferralsList}
          title={translate(translations.yourReferrals)}
        />
        <RedirectRow Icon={InfoOutlinedIcon} onPress={goToHowItWorks} title={translate(translations.howItWorks)} />
      </View>
    </ScrollView>
  )
}

export default Referrals
