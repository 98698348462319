import { useContext, useEffect, useState } from 'react'

import { buttonVariants } from 'src/components/Buttons/ButtonNew'
import { customModalColorSchema, customModalVariants } from 'src/components/Modal/CustomModal/types'
import { DisplayModalContext } from 'src/context/DispalyModalContext'
import { getTaskConfirmationModalContent } from 'src/scenes/Main/Profile/TasksList/TaskRow/utils'
import { ProfileTaskType } from 'src/scenes/Main/Profile/types'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  isGB: boolean
  onCloseCallback?: () => void
  task: ProfileTaskType
}

const useTaskConfirmationModal = ({ isGB, onCloseCallback, task }: Props) => {
  const [showModal, setShowModal] = useState(false)
  const modalContext = useContext(DisplayModalContext)
  const modalContent = getTaskConfirmationModalContent(task, isGB)

  const closeModal = () => {
    setShowModal(false)
    onCloseCallback?.()
  }
  const openModal = () => setShowModal(true)

  useEffect(() => {
    modalContext.setParams({
      buttons: [
        {
          onPress: closeModal,
          title: translate(translations.iUnderstand),
          variant: buttonVariants.containedDefault,
        },
      ],
      colorSchema: customModalColorSchema.default,
      description: modalContent?.info,
      onClose: closeModal,
      title: modalContent?.title || '',
      variant: customModalVariants.filled,
    })
    modalContext.setDisplay(showModal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal])

  return { openModal }
}

export default useTaskConfirmationModal
