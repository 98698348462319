import React from 'react'
import { Text, View } from 'react-native'

import { colorsNew } from 'src/styles'
import { ErrorOutlinedIcon } from 'src/icons'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type Props = {
  onHoldAt: string
}

const BlockedScreen = ({ onHoldAt }: Props) => (
  <View style={styles.blockedScreenWrapper}>
    <ErrorOutlinedIcon fill={colorsNew.redesign.error.dark} size={40} />
    <Text style={styles.blockedScreenTitle}>{translate(translations.accountOnHold)}</Text>
    <Text style={styles.blockedScreenInfo}>{translate(translations.accountOnHoldInfo, { date: onHoldAt })}</Text>
  </View>
)

export default BlockedScreen
