import React, { useEffect, useRef, useState } from 'react'
import { Text, View } from 'react-native'

import { ReferralFormatted } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/types'
import { bonusShareRatioToVariant, referralsStates } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import ReferChip from 'src/scenes/Main/ReferAFriendOrFoe/components/ReferChip'
import ReferralModal from '../ReferralModal/ReferralModal'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'

type TProps = {
  referral: ReferralFormatted
  section: keyof typeof referralsStates
}

const ReferralRow = ({ referral, section }: TProps) => {
  const [isActionInformationVisible, setIsActionInformationVisible] = useState(false)
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false)

  const closeReferralModal = () => setIsReferralModalOpen(false)
  const openReferralModal = () => setIsReferralModalOpen(true)

  const timer = useRef<NodeJS.Timeout>()

  const isLeftHumlySection = section === referralsStates.educatorLeftHumly
  const isPaidOutSection = section === referralsStates.paidOut

  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current)
  }, [])

  const handleChipOnPress = () => {
    if (referral.locked || isLeftHumlySection) {
      setIsActionInformationVisible(true)
      timer.current = setTimeout(() => setIsActionInformationVisible(false), 2000)
    } else {
      openReferralModal()
    }
  }

  return (
    <View style={styles.container} testID={`referralRow-${section}`}>
      <View>
        <Text style={styles.info}>{referral.applicantName}</Text>
        <Text style={styles.subInfo}>
          {isPaidOutSection
            ? translate(translations.referralPaidOutInfo, {
                date:
                  referral.variant === bonusShareRatioToVariant.TAKE_ALL
                    ? referral.refererPaidAt
                    : referral.referredPaidAt,
              })
            : translate(translations.referralAppliedInfo, { date: referral.appliedAt })}
        </Text>
      </View>
      <View style={styles.chipWrapper}>
        <ReferChip
          disabled={referral.locked || isLeftHumlySection}
          onPress={handleChipOnPress}
          variant={referral.variant}
        />
        {isActionInformationVisible ? (
          <Text style={styles.actionInfo}>{translate(translations.selectionNoLongerAvailable)}</Text>
        ) : null}
      </View>
      <ReferralModal
        closeModal={closeReferralModal}
        educatorName={referral.applicantName}
        referralId={referral.id}
        referralVariant={referral.variant}
        showModal={isReferralModalOpen}
      />
    </View>
  )
}

export default ReferralRow
