import { StyleSheet, Dimensions, StyleProp, ViewStyle } from 'react-native'

import { colors, colorsNew, padding, fonts } from 'src/styles'

const { width } = Dimensions.get('window')
export const MODAL_ROW_HEIGHT = 54

const styles = StyleSheet.create({
  container: {
    marginBottom: padding.xs,
  },
  modalContainer: {
    backgroundColor: colors.white,
    paddingBottom: padding.lg,
  },
  modalListFooter: {
    height: 100,
  },
  modalListHeader: {
    borderBottomColor: colors.grey._500,
    borderBottomWidth: 1,
    marginBottom: 10,
    paddingBottom: 8,
  },
  modalRow: {
    alignItems: 'center',
    flexDirection: 'row',
    height: MODAL_ROW_HEIGHT,
    paddingHorizontal: 25,
    paddingVertical: 16,
    width: '100%',
  },
  modalRowLabel: {
    color: colorsNew.redesign.text.primary,
    fontSize: 16,
    fontFamily: fonts.poppinsMedium,
    fontWeight: 'normal',
    lineHeight: 20,
    marginRight: 8,
    maxWidth: width,
  },
  modalRowSelected: {
    backgroundColor: colors.success.light,
  },
  menuStyle: {
    backgroundColor: colors.white,
    borderRadius: 4,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    marginHorizontal: 1,
    flex: 1,
    zIndex: 1,
  },
  optionRow: {
    paddingHorizontal: 35,
    paddingVertical: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  circleContainer: {
    borderRadius: 50,
    borderWidth: 1,
    borderColor: colors.grey._400,
    height: 20,
    width: 20,
    backgroundColor: colors.white,
    marginRight: 10,
  },
  circleFill: {
    flex: 1,
    borderRadius: 50,
    margin: 2,
    backgroundColor: colors.primary,
  },
})

export default styles
