import { onboardingSv } from 'src/locales/onboardingSv'

export default {
  aboutMe: 'Beskrivning av dig själv. Beskrivningen visas för skolan',
  aboutMeDescription:
    'Här skriver du kort beskrivning av dig själv som skolorna sedan kan se. Du kan exempelvis skriva lite om din bakgrund eller varför du tycker det är kul att arbete med att utbilda barn och unga.',
  aboutMePlaceholder: 'Beskriv din profil här.',
  academy: 'Akademi',
  accept: 'Tacka ja',
  acceptAgreements: 'Jag accepterar',
  acceptedInfo:
    'Nu går vi igenom alla kandidater som har visat intresse för uppdraget och presenterar dig och de andra för skolan. Du får besked via telefon eller mejl när skolan har tagit ett beslut. Tveka inte att höra av dig om du har några frågor!',
  acceptedInfoDescription:
    'Grattis! Skolan har utifrån din ansökan valt att tillsätta dig för detta uppdrag. Vänligen bekräfta detta via knappen ovan så skolan vet att informationen har nått dig. ',
  acceptedInfoTitle: 'Uppdraget är ditt!',
  acceptOnCallDescription:
    'Är du säker på att du vill vara expressvikarie denna dag?\n\nHa din telefon igång och under uppsikt från kl. 06.15. Du kommer att få en notifiering där så fort vi har hittat ett uppdrag för dig.',
  accountOnHold: 'Ditt konto är pausat!',
  accountOnHoldInfo:
    'Ditt konto pausades den %{date} på grund av en pågående utredning. Vänligen kontakta vårt supportteam om du har frågor.',
  addArea: 'Lägg till ny',
  addAvailability: 'Lägg till tillgänglighet för att få notiser',
  addAvailabilityWeek: 'Lägg mig tillgänglig hela veckan',
  addComment: 'Vänligen ange en kommentar.',
  addNewAddress: 'Lägg till ny adress',
  address: 'Adress',
  addressEx: "Adress - Exempelvis 'Skolgatan 2'",
  addSchool: 'Lägg till ny',
  adjustMyPreferences: 'Justera mina jobbinställningar',
  agreementsInfoTitle: 'Samtycke',
  agreementTermsAndConditions: 'Jag godkänner de fullständiga villkoren.',
  alertUserRequestedTitle: 'Skynda, du har blivit önskad! 💚🦸',
  alertUserRequestedDescription:
    'Du måste ha gjort ett strålande intryck förra gången eftersom %{school} vill ha dig tillbaka! Vänligen svara om du kan eller inte. Skolan vill ha ditt svar så snart som möjligt!',
  alertFlexibleOfferTitle: 'Jobbet startar snart! ⏱',
  alertFlexibleOfferDescription:
    'Detta uppdrag börjar väldigt snart. Därför har %{school} godkänt att du anländer så snart som möjligt från %{startTime}. Kom ihåg att rapportera korrekt starttid i appen efter arbetsdagen är slut. ',
  alertUnconfirmedTitle: 'Dagens Expresspass för dig!  🏎💨',
  alertUnconfirmedDescription: 'Här kommer dagens jobb. Bekräfta nedan att du kommer!',
  alertOfferCanceledTitle: 'Jobbet har blivit avbokat! 🥀',
  alertOfferCanceledDescription:
    'Tyvärr har %{school} avbokat jobbet och har inte längre ett behov.  Kika gärna om det finns något annat uppdrag som passar dig, eller kontakta oss så hjälper vi dig. ',
  alertOfferNotAvailableTitle: 'Jobbet är inte längre tillgängligt.',
  alertOfferNotAvailableDescription:
    'Ledsen, men du fick inte jobbet på %{school} den här gången. Kika gärna om det finns andra lediga jobb som kan passa dig. 👀',
  alertUserNotCompliant:
    'Åh nej! Det verkar som att ditt utdrag ur belastningsregistret är inaktuellt. Du behöver förnya detta för att kunna söka jobb i appen. I din profil kan du ladda upp ett nytt belastningsregister.',
  alertWorkPermitInvalid: '',
  alertWorkPermitInvalidTitle: '',
  alertUserAppliedTitle: 'Tack för din ansökan! ️⏳',
  alertUserAppliedDescription:
    'Tyvärr kan vi inte ge besked direkt på detta uppdrag. Du kan fortfarande söka andra jobb samma dag för att öka dina chanser. Vi ser till att du inte blir dubbelbokad. ',
  alertPendingTitle: 'Du fick jobbet, bekräfta nu! 🚀',
  alertPendingDescription: '',
  alertConfirmedTitle: 'Jobbet är ditt! 🎉',
  alertConfirmedDescription:
    'Vi tror du kommer passa jättebra för det här uppdraget. Hör gärna av dig om du riskerar att komma sent eller har några frågor. Stort lycka till!',
  allAmountsShown: 'Alla belopp som anges är',
  allInformation:
    'Nedan finns all viktig information om uppdraget samlad. Skolan förväntar sig att du är där senast %{startTime}. Tänk på att alltid ha med dig din namnbricka och ditt utdrag ur belastningsregistret i fysisk form till uppdraget.',
  allYourCompletedJobs: 'Alla Dina Genomförda jobb',
  almostThere: 'Snart är uppdraget ditt!',
  alreadyAppointed: 'Uppdraget redan tillsatt',
  alreadyAppointedToYou: 'Bokningen har redan blivit tillsatt till dig av dig själv eller av supporten',
  AM: 'FM',
  androidRateUs: 'Ge oss fem stjärnor!',
  answerQuestions: ' Svara på alla frågor',
  anyQuestionLabel: 'Har du några frågor?',
  anyQuestionsAboutTheTermsContactUs: 'Har du frågor om villkoren, kontakta oss på support@humly.io',
  applicationDescription: 'Är du en passande matchning för detta jobb? Ansök nu! Du får svar från skolan senast',
  applicationFormHeader: 'Kul att du är intresserad av jobbet! 🎉',
  applicationFormDescription: 'Beskriv gärna varför och vad som gör att just du passar för den här rollen?',
  applicationFormPlaceholder: 'Vänligen beskriv',
  applicationFormSubmit: 'Skicka ansökan',
  applicationFormSubmitted: 'Ansökan skickad!',
  applicationInfo1:
    'Din jobbansökan är bindande, så det är viktigt att du bara gör jobbansökan till uppdrag du verkligen kan arbeta. Du kan göra flera jobbansökningar per dag. ',
  applicationInfo2:
    'Vi strävar efter ge dig svar så snart som möjligt. Tidsgränsen varierar och  kan hittas i bokningsinformationen, men du får alltid svar inom 24h. Du får svaret här i appen så snart skolan beslutat.',
  applicationInfo3: 'Om skolan väljer dig är du tvungen att så fort du kan bekräfta tillsättningen via appen.',
  applicationInfo: 'Nu testar vi ett nytt sätt att tillsätta uppdrag på några få uppdrag. Så här går processen till: ',
  applicationInfoButton: 'Hur funkar jobbansökan?',
  applicationInfoConfirmButton: 'Jag förstår!',
  applicationInfoInterestModalHeader: 'Nytt sätt att ta uppdrag - jobbansökan!',
  applicationInfoModalHeader: 'Nytt sätt att ta uppdrag - jobbansökan!',
  applicationInfoTitle1: '1. Jobbansökan för ett eller flera uppdrag',
  applicationInfoTitle2: '2. Svar inom 24h',
  applicationInfoTitle3: '3. Om skolan väljer dig måste du via appen bekräfta att du har nåtts av detta.',
  applicationInfoTitle: 'Jobbansökan är bindande',
  applicationModalDescription:
    'Om en skola väljer dig för ett uppdrag som du ansökt till får du information om detta i appen och du måste då alltså ta det. Du kan ansöka om flera uppdrag för samma dag.',
  applicationModalHeader: 'Vill du göra en ansökan till detta uppdrag?',
  applicationModalInfo: 'Du är på väg att göra en ansökan för dessa datum: ',
  applicationModalTerms1: 'Jag kan arbeta hela uppdraget.',
  applicationModalTerms2: 'Jag förstår att jag är ',
  applicationModalTerms3: ' att arbeta detta uppdrag, om skolan väljer att tillsätta mig.',
  applicationModalTerms4: 'Jag förstår att jag kommer få svar senast%{limit}.',
  applicationModalTermsTitle: 'Läs igenom och godkänn villkoren, så vi vet att du är införstådd med vad som gäller!',
  applications: 'Ansökningar',
  appliedHeader: 'SKOLAN ÄR MEDDELAD!',
  appliedTitle: 'Detta händer nu',
  apply: 'Gör ansökan',
  applyForJob: 'Jag är intresserad',
  applyForProposal: 'Jag är intresserad!',
  applyHeader: 'Visa intresse för uppdraget',
  applyIcon: 'Ansök',
  applyNow: 'Ansök nu!',
  applySubstituteTeacher: 'Ansök nu',
  appointDirectly: 'Först till kvarn - Tillsättning direkt',
  appointed: 'Bokad',
  appointedIcon: 'Tillsatt',
  appointedJobMessage1: 'Hej! Kul att du var intresserad av detta uppdrag. Uppdraget på ',
  appointedJobMessage2:
    'har tyvärr redan tagits av en annan utbildare. Kanske finns det något annat uppdrag som passar dig?',
  appointRequest: 'Du är önskad - tillsätt direkt',
  approximateDates: 'Ungefärliga datum',
  area: 'Område',
  areaPreferences: 'Reseavstånd',
  areYouSure: 'Är du säker på att du vill',
  arrivalRoutines: 'Rutiner vid ankomst',
  asAgreed: 'Enligt avtal',
  assignment: 'Uppdrag',
  assignmentHistory: 'Uppdragshistorik',
  availability: 'Tillgänglighet',
  availabilityAllDays: 'Alla dagar',
  availabilityApplyTo: 'Tillämpa på:',
  availabilityConfirm: 'Bekräfta uppdatering',
  availabilityDateRange: 'Datum:',
  availabilityEmptyText1: 'Ingen tillgänglighet tillagd denna vecka.',
  availabilityEmptyText2:
    'Detta innebär att du inte kommer få notiser och riskerar att missa jobb som matchar din profil.',
  availabilityEmptyText3: 'Sätt din tillgänglighet nu!',
  availabilityErrorMsg: 'Du kan inte ta bort tillgänglighet på dagar du är bokad',
  availabilityHeader1: 'Tips!',
  availabilityHeader2:
    'Om du vill göra ändringar i din tillgänglighet en dag i taget, kan du göra det enkelt i schemavyn.',
  availabilityModalButton: 'Ok, jag förstår!',
  availabilityModalDescription:
    'Tanken med notiser är att underlätta för dig som vill ta uppdrag. De tider du anger dig som tillgänglig här är inte bindande på något sätt, utan gör helt enkelt bara att du får pushnotiser så fort det kommer in uppdrag som matchar de tider du vill arbeta. Du väljer sedan själv om du vill tacka ja eller nej till uppdragen.\n\nLägger du inte in tillgänglighet, kan allstå chansen att hitta uppdrag minska avsevärt. Detta eftersom du då inte får någon notis när nya uppdrag blir tillgängliga.\n\nVill du vara ledig lämnar du bara tillgängligheten tom. På så sätt stör vi dig inte med notiser när du inte vill arbeta.',
  availabilityModalTitle: 'Vi vill hjälpa dig att komma ut i arbete!',
  availabilityNotavailableText1: 'Du har sagt att du inte kan arbeta denna veckan.',
  availabilityNotavailableText2:
    'Detta innebär att du inte kommer få några notiser, även om uppdraget matchar din profil.',
  availabilityNotavailableText3: 'Vill du ändra på det?',
  availabilitySelected: 'Valda veckodagar',
  availabilitySetAs: 'Sätt som:',
  availabilityTitle: 'När vill du arbeta?',
  availabilityUpdated: 'Tillgänglighet uppdaterad!',
  availabilityWarning: 'Tillgängligheten måste vara minst 1h.',
  availabilityWarningMsg: 'Korta tidsintervall kan göra det svårt att hitta uppdrag.',
  available: 'Tillgänglig',
  availableExpressJobs: 'Lediga Expressjobb',
  availableJobs: {
    one: '%{count} ledigt jobb',
    other: '%{count} lediga jobb',
  },
  availableSchoolsInTheArea: '%{schoolCount} skolor i området',
  availableShortTermOffers: 'Kortare jobb tillgängliga just nu',
  avatarError: 'Profilbild uppladdningsfel',
  back: 'Tillbaka',
  backModalCancel: 'Ja, lämna',
  backModalLeave: 'Tillbaka',
  backToWork: 'Börja jobba',
  bankAccountNumber: 'Kontonummer',
  bankClearingNumber: 'Clearingnummer',
  bankDetails: 'Bankuppgifter',
  bankName: 'Namn på banken',
  beChallenged: 'Utmanas och utvecklas',
  beforeTax: 'före skatt',
  beforeYouStart: 'Före ditt uppdrag 🎬',
  beforeYouStartInfo:
    'Du kan också förbereda dig genom att titta på denna film om att arbeta på %{levelName}.\n\nFilmen är %{videoDuration} min',
  blank: 'Kan ej vara tomt',
  blankOption: 'Check one option',
  blankPassword: 'Kan ej vara tomt',
  bonusAmountCanVary:
    'Bonusbeloppet kan variera över tid och det är den bonus som anges i vår app vid tillfället som din vän skapar sitt konto som gäller till grund för bonusutbetalningen.',
  booked: 'Bokad',
  booking: 'Bokning',
  bookingAlertUnavailableDescription:
    'Du är registrerad som frånvarande från detta uppdrag. Stämmer detta behöver du inte göra något. Om detta inte stämmer behöver du kontakta support omgående.',
  bookingAlertUnavailableTitle: 'Registrerad frånvaro.',
  bookings: 'Bokningar',
  bookingsPlural: {
    one: '%{count} Bokning',
    other: '%{count} Bokningar',
    zero: '%{count} Bokningar',
  },
  bookingWasCancelled: 'Denna bokning blev avbokad',
  bookingWithUnpaidTime: 'Denna dag har %{unpaidTime} minuter obetald rast.',
  breakTime: '%{breakTime} minuter rast',
  calendarEventTitle: 'Uppdrag på %{schoolName}',
  calendarIsConnected: 'Kalendern är ansluten',
  calendarSynchronizationSuccess: 'Din kalender uppdateras för de kommande 30 dagarna',
  call: 'Ring',
  callPhone: 'Ring %{phone}',
  callSchoolUser: 'Ring %{name}',
  callUs: 'Ring oss',
  cameraNotAvailable: 'Kamera inte tillgänglig på enheten',
  cancel: 'Avbryt',
  cancelled: 'Avbokad',
  cantEditTimeSheet: 'För sent att ändra',
  cantHandleUrl: 'Misslyckades att öppna URL-länk',
  changeCountry: 'Ändra landsinställningar',
  changePassword: 'Ändra lösenord',
  changeSettings: 'Ändra inställningar',
  chatEmpty: 'Har du några frågor till oss?',
  chatEmptyDescription: 'Skicka ett meddelande så hjälper vi dig!',
  chatWarningInfo:
    'Supporten är för närvarande stängd. Du kan skicka ditt meddelande ändå, så återkommer vi så snart vi kan!',
  checkDates:
    'Du är på väg att ta ett uppdrag. Tänk på att det är bindande och att du sedan inte kan ångra ditt åtagande. Tänk därför på att du verkligen vet att du kan arbeta:\n%{dates}\ninnan du tackar ja. Du får endast jobba om du är fullt frisk och inte har några sjukdomssymptom.',
  checkDetails: 'Detaljer',
  checkLongTermJob: 'Vad blir ditt nästa jobb?',
  checkedIn: 'Incheckad',
  checkedOut: 'Utcheckad',
  checkIn: 'Checka in',
  checkInDescription:
    'Du ska känna dig trygg med att du får rätt lön för ditt jobb. Checka in när du anländer till skolan och checka ut när du lämnar skolan för dagen. Skolan får sedan bekräfta eller neka din angivna tid.',
  checkInInfo: 'Välj tiden du anlände till skolan och påbörjade arbetsdagen',
  checkInTitle: 'Varför ska jag checka in och ut?',
  checkOut: 'Checka ut',
  checkOutInfo:
    'Välj tiden du lämnade skolan och avslutade arbetsdagen.\nDu kan ej välja en tidigare tid än bokningens ursprungliga. Blev ditt pass förkortat ber vi dig kontakta Humlys support.',
  chipError: 'Error',
  chipNotPermitted: 'Inte tillåtna',
  chipPermitted: 'Tillåtna',
  chooseFromLibrary: 'Välj från bibliotek…',
  chooseTime: 'Välj en tid',
  chooseYourBonusSubHeading: 'När din vän (eller fiende) ansöker till Humly väljer du hur bonusen ska betalas ut',
  chooseYourBonusText: 'Ge bort allt? Dela lika 50/50?',
  chooseYourBonusText2: 'Eller behålla allt för dig själv?',
  choseArrivalTime: 'Välj din förväntade ankomsttid.',
  city: 'Stad',
  cityEx: "Ort - Exempelvis 'Stockholm'",
  clientInfo: 'Info om arbetsplatsen',
  clientDocuments: 'Viktiga dokument',
  clientInformation: 'Information om arbetsplatsen',
  clientPreferences: 'Arbetsplatser',
  clientProceduresArriving: 'Rutiner vid ankomst',
  clientProceduresLeaving: 'Rutiner vid avresa',
  clientRoutines: 'Andra viktiga rutiner',
  close: 'Stäng',
  closed: 'Stängt',
  closeJobsDescription:
    'Välj alternativ nedan för att matchas med bokningar i specifika områden eller i hela regionen. Många väljer här exempelvis sin hemadress eller adressen där de studerar. Du kan ändra din val när du vill. Detta påverkar endast dina pushnotiser och listan med matchande uppdrag - du kommer alltid kunna se alla lediga uppdrag i den generella uppdragslistan.',
  closeMessage: 'Stäng meddelandet',
  comment: 'Kommentar',
  completedJobs: 'Genomförda Jobb',
  completedJobsPerMonthSince: 'Genomförda jobb per månad sedan %{date}',
  completedTimesheet: 'Inlämnad tidrapport',
  creditsAcknowledgements: 'Credits and acknowledgements',
  creditsAcknowledgementsDescription: 'Programvaror från tredje part',
  jobsCompletedInMonthInfo:
    'Notera att endast genomförda jobb visas i denna översikt. Jobb som blev avbokade, du var sjukanmäld ifrån, eller som av någon anledning inte genomfördes visas inte här.',
  completeRecruitmentProcess: 'Genomför din rekrytering!',
  completeRecruitmentProcessDescription:
    'Varje dag matchar vi vikarier med hundratals jobb. Gör klart din rekryteringsprocess så fort som möjligt, för att ta del av alla uppdrag.',
  completeTimesheetDescription: 'Du kan lämna in den som den är eller redigera den ifall det behövs',
  completeTimesheetSuccessTitle: 'Tidrapport klar!',
  completeYourTimesheet: 'Lämna in tidrapport',
  complianceExpired: 'Belastningsregister inaktuellt | Förnyelse krävs',
  complianceFulfilled: 'Du är redo för jobb!',
  complianceWarning: 'Belastningsregister blir snart inaktuellt | Förnya snarast',
  confirm: 'Bekräfta',
  confirmAppointment: 'Bekräfta tillsättning',
  confirmed: 'Bekräftat',
  confirmedHeader: 'UPPDRAGET ÄR DITT! SKOLAN ÄR MEDDELAD!',
  confirmedJobs: 'Bekräftade Jobb',
  confirmedNorthpassButtonDescription: 'Ta del av våra smarta tips!',
  confirmedNorthpassButtonText: 'Inför ditt uppdrag inom %{level}',
  confirmExpressButton: 'Jag kommer!',
  confirmExpressDescription1:
    'Vi har hittat detta passande uppdrag för ditt expresspass idag. Läs igenom uppdraget och tryck på knappen nedan för att bekräfta till skolan att du kommer.',
  confirmExpressDescription2:
    'Om du av någon anledning inte kan, eller om du inte hinner dit i tid måste du ringa till oss på supporten. Du förväntas vara på plats på skolan kl. %{startTime}.',
  confirmExpressOverlayButton: 'BEKRÄFTA NUVARANDE EXPRESS',
  confirmExpressOverlayDescription:
    'Du har tilldelats ett uppdrag idag. Det är viktigt att du bekräftar uppdraget här i appen så att skolan blir informerad att du kommer.\n[VISA OCH BEKRÄFTA UPPDRAGET]',
  confirmIcon: 'Bekräfta',
  connectCalendar: 'Koppla till din kalender',
  connectionLost: 'Ingen uppkoppling',
  connectionLostDescription:
    'Ett fel inträffade när vi skulle hämta information från vårt system. Det verkar som du saknar internetuppkoppling. \nVänligen kontrollera din uppkoppling och försök igen.',
  contactDetails: 'Kontaktuppgifter',
  contactDetailsInfo: `Vi finns tillgängliga alla helgfria vardagar för att hjälpa dig. Föredrar du att ringa eller mejla?`,
  contactDetailsSubtitle: 'Mejla eller ring oss',
  contactHumly: 'Kontakt på Humly',
  contactHumlyPlaceholder: 'Vänligen kontakta support@humly.io',
  contactPerson: 'Behöver du hjälp under uppdraget? Kontakta skolan',
  contactPersonButtonText: 'Kontakta skolan',
  contactSupport: 'Kontakta support',
  contactUs: 'Kontakta oss',
  continue: 'Fortsätt',
  contract: 'Avtalstyp:',
  copyLink: 'Kopiera din länk',
  copyPhoneNumber: 'Kopiera telefonnummer',
  copyToWholeWeek: 'Copy to whole week',
  currency: 'kr',
  currencyLong: 'kronor',
  currentExpress: 'Nuvarande\nExpress',
  currentPasswordError: 'Felaktigt nuvarande lösenord, försök igen.',
  dataPolicy: 'Data policy',
  date: 'Datum',
  days: '%{days} dagar',
  deleteAccount: 'Radera konto',
  deleteAccountButton: 'Radera mitt konto',
  deleteAccountRequested: 'Kontoborttagning begärd %{date}',
  deleteAccountDescription:
    'Om du vill radera ditt konto hos Humly kan du starta processen genom knappen nedan. Vi är skyldiga enligt lag att hålla data om din profil i två år efter ditt senast genomförda jobb.\nNär du bett oss radera ditt konto kommer vi att granska din profil och besluta om vi kan radera det direkt eller om vi behöver hålla datan i en övergångsperiod innan vi kan radera den. Vi kommer återkoppla med mer information om vårt beslut via e-post. Du kan hitta mer information om vår datahantering i vår datapolicy.',
  deleteApplicationNotPossible:
    'Du kan inte längre ta bort din ansökan eftersom du har blivit presenterad för skolan som nu är nära att välja dig. Kontakta Humlys support vid frågor.',
  deleteApplictionModalDescription:
    'Är du säker på att du vill ta bort din ansökan? Du kommer inte kunna göra en ny ansökan för detta specifika jobb.',
  deleteApplictionModalTitle: 'Är du säker på att du vill ta bort din ansökan?',
  deleteMyApplication: 'Ta bort min ansökan',
  deleteNotification: 'Släng',
  department: 'Förskoleavdelning',
  departureRoutines: 'Rutiner vid avresa',
  descriptionWelcome:
    'Välkommen till vår app! Nu följer en kort guide där vi går igenom appens viktigaste funktioner. Vi rekommenderar dig att ta dig igenom den i lugn och ro, men du kan alltid återvända till guiden senare om du behöver det',
  desiredWorkloads: 'Önskad arbetsmängd',
  dateMissing: '',
  details: 'Mer',
  differentPassword: 'Det nya lösenordet kan inte vara samma som det nuvarande',
  done: 'Klart',
  dontExclude: 'Matcha med denna skola',
  dontLikeSchool: 'Jag vill inte arbeta på skolan',
  dontShowAgain: 'Visa inte detta meddelande igen',
  dontUseForMatching: 'Använd inte för matchning',
  downloadPayslip: 'Se lönespecifikation',
  duration: 'Tidsperiod:',
  earned: 'Tjänat In 💰',
  edit: 'Redigera',
  editAddress: 'Redigera adress',
  editAvailability: 'Ändra tillgänglighet',
  editAvailabilitySubtitle:
    'Om du avmarkerar en dag gör det dig otillgänglig den dagen. Du kan alltid ändra detta senare.',
  editBreakModalDescription: 'Hur många minuter rast har du haft idag?',
  editBreakModalLabel: 'Ange antalet minuter',
  editBreakModalTitle: 'Ändra din rast',
  editionIsBlocked:
    'Namn och adress är känsliga uppgifter som endast Humly kan ändra. Kontakta oss om du vill ha hjälp att ändra detta.',
  editTimeSheet: 'Ändra tidrapport',
  education: 'Vad studerade du senast?',
  educationDescription:
    'Skolor är ofta intresserade av att veta vad du har studerat eller studerar just nu. Vilken utbildning läser du eller läste du senast?',
  email: 'E-post',
  emailAddress: 'Mejladress',
  emailCopied: 'Mejladress har kopierats',
  emailCopy: 'Kopiera mejladress',
  emailEmployments: 'Frågor om din anställning',
  emailJobs: 'Frågor om specifika uppdrag',
  emailSend: 'Skicka ett mejl',
  emailTitle: 'Felrapport från appen',
  emptyActiveSchools: 'Fortsätt matcha med',
  emptyActiveSchoolsDescription: 'Matchas inte med några tidigare arbetsplatser. \nAlla är exkluderade från matching. ',
  emptyAllOffersListDescription:
    'Kom tillbaka tidig morgon eller under \neftermiddagen när de flesta nya bokningar \nfrån skolorna kommer in.',
  emptyAllOffersListTitle: 'Här var det tomt...',
  emptyExcludedSchoolsDescription: 'Inga tidigare arbetsplatser uteslutna från matchning.',
  emptyExpressDescription:
    'Vi har inga lediga platser som Express just nu. \nTips: Vanligtvis släpps nya platser en till två \nveckor innan jobbet ska genomföras.',
  emptyExpressTitle: 'Här var det tomt...',
  emptyHistory: 'Inget att rapportera här',
  emptyHistoryDescription: 'Vi meddelar dig när det finns nya \ntidrapporter att lämna in.',
  emptyHistoryUnreported: 'Allt är inlämnat!',
  emptyHistoryUnreportedDescription:
    'Det känns väl bra? Nu är det upp till \nskolorna och vårt ekonomiteam att \nhänga med i ditt tempo.',
  emptyReferralsInfo:
    'Det är väl klart du gör! Har du några vänner som du skulle dela det med eller behålla allt för dig själv?',
  emptyReferralsTitle: 'Gillar du att få pengar?',
  emptySchoolsDescription: 'Kom tillbaka hit om du varit på en arbetsplats \ndu inte vill arbeta hos igen',
  emptySchoolsTitle: 'Inget att justera här ännu',
  endOnHold: 'Ditt konto är pausat, vill du aktivera det igen?',
  endTime: 'Sluttid',
  enterMandatoryFirstName: '',
  enterMandatoryLastName: '',
  enterMandatoryNationality: '',
  enterValidCity: 'Ort får endast innehålla bokstäver A-Ö',
  enterValidEmail: 'Ange en giltig email',
  enterValidEmailLogin: 'Vänligen lägg till en giltig e-postadress.',
  enterValidFirstName: 'Förnamn får endast innehålla bokstäver A-Ö',
  enterValidLastName: 'Efternamn får endast innehålla bokstäver A-Ö',
  enterYourEmail: 'Ange din mejl adress',
  equalPasswords: 'Nya lösenord matchar inte, de måste vara identiska.',
  error: 'Fel',
  errorDateAvailability: 'Slutdatum måste vara efter startdatum',
  errorMessage: 'Detta uppdrag har tyvärr redan tillsatts av en annan vikarier och är inte längre tillgängligt.',
  errorReportApplicationFailed:
    'Försök igen om en stund. Kontakta vår support om problemet kvarstår, så hjälper vi dig.',
  errorReportTitle: 'Det verkar som om våra servrar tar en paus just nu...',
  errorSelectedDays: 'Välj minst en veckodag',
  errorSendingEmail: 'Vi kunde inte öppna din mejlapp.',
  errorSendingNotification:
    'Vi kan tyvärr inte skicka pushnotiser till din telefon. Kontakta oss via support@humly.io så ska vi reda ut varför.',
  errorTimeAvailability: 'Sluttid måste vara efter starttid',
  errorTitle: 'Felmeddelande',
  estimatedSalary: 'Estimerad lön',
  exclude: 'Exkludera från matchning',
  excludedSchoolsTitle: 'Matcha inte med',
  excludeSpecificClients: 'Välj bort skolor',
  express: 'Express',
  expressAlertConfirmedDescription:
    'Var tillgänglig från kl 06:15 denna dag för att acceptera det uppdrag vi skickar till dig via appen.',
  expressAlertConfirmedTitle: 'Du är uppbokad på express! ⚡',
  expressAlertDescription:
    'Som express är du garanterad minst 5h lön. Du behöver vara tillgänglig från kl 06:15 för att acceptera uppdrag via appen.',
  expressAlertTitle: 'Detta är ett express uppdrag!  ⚡',
  expressAlertUnbookedDescription:
    'Tack för att du var tillgänglig - som expressvikarie hjälper du skolor och förskolor när de behöver det som mest! Du får din garanterade lön för idag. Vi hoppas att du tar fler expressuppdrag i framtiden.',
  expressAlertUnbookedTitle: 'Du tilldelades inget uppdrag idag 👀',
  expressAppointment: 'Denna bokning har blivit tillsatt av en annan utbildare',
  expressDialogInfoText:
    'Är du mer nyfiken på vad expressvikariat innebär, slå oss en pling, eller kontakta oss på support@humly.io',
  expressDialogList1Option1:
    'Redo att bekräfta uppdrag i appen mellan kl. 6:15-9:00, max 5 min svarstid. (Notera att om du inte svarar, betraktas det som ogiltig frånvaro och ingen garantilön utgår.)',
  expressDialogList1Option2: 'Flexibel med arbetstider mellan kl. 7:00-17:30.',
  expressDialogList1Option3: 'Trygg att hantera olika uppdrag och åldrar.',
  expressDialogList2Option1:
    'Garanterad minst 5 timmars lön. Hittar vi inget uppdrag till dig får du ändå betalt för 5 timmar, så länge du är kontaktbar i appen.',
  expressDialogList2Option2: 'Uppdrag inom rimlig restid.',
  expressDialogList2Option3: 'Uppdrag som matchar de åldrar/roller som expressuppdraget beskriver.',
  expressDialogListTitle1: 'Vad som förväntas av Dig:',
  expressDialogListTitle2: 'Vad du kan förvänta dig från Humly:',
  expressDialogSubtitle1: 'Hur fungerar Expressjobb?',
  expressDialogText1:
    'Är du en flexibel hjälte och vill vara garanterad att få arbeta? Då är expresspass något för dig!',
  expressDialogText2:
    'Som Expressvikarie tilldelas du ett uppdrag av Humly. Du ska vara redo att ta emot och bekräfta ditt tilldelade uppdrag under morgonen från kl. 6:15. \nDu ska vara redo att bege dig till uppdraget för att hinna fram i tid. Om du riskerar att komma försent ska detta meddelas till Humly så snart som möjligt. ',
  expressDialogTitle: 'Mer information om Express',
  expressExtraInfo: 'Eftersom du var expressvikarie detta datum är du garanterad fem timmars lön.',
  expressFactTitle: '3 Snabba om att vara Expressvikarie:',
  expressFactText1: 'Garanterad lön',
  expressFactSubtext1: 'Minst 5h betalt.',
  expressFactText2: 'Humly bokar jobbet åt dig',
  expressFactSubtext2: 'Besked på morgonen kl. 6-9, eller kvällen innan. ',
  expressFactText3: 'Så nära dig som möjligt',
  expressFactSubtext3: 'Max 90min restid.',
  expressHeaderDetails: 'Tillgänglig från kl 6:15',
  expressHours: 'Minst 5h',
  expressJobTitle: 'Expressjobb tillgängliga just nu',
  expressSummaryPayment: 'Som express är du garanterad  minst fem timmars lön.',
  expressSummaryReportSubtitle:
    'Eftersom ingen förskola eller skola behövde din hjälp denna dag så behöver du inte göra någon rapportering. Du får garantilönen som en del av nästa löneutbetalning.',
  expressSummaryReportTitle: 'Ingen rapportering krävs',
  expressSummarySubtitle: 'Inga skolor eller förskolor behövde din hjälp. Du kommer få betalt för fem garantitimmar.',
  expressSummaryTitle: 'Tack för att du var express!',
  extent: 'Omfattning',
  faq: 'FAQ',
  faqSubtitle: 'Svaret på din fråga kanske finns här?',
  feedback: 'Feedback',
  feedbackComment: 'Beskriv din upplevelse',
  feedbackDescription:
    'Din feedback hjälper oss utveckla Humly. Skolor kan endast se de genomsnittliga betygen, så de kommer inte se dina specifika svar.',
  feedbackDescriptionNew:
    'Din feedback är viktig för att vi ska kunna förbättra din och andras upplevelse och arbetsmiljö. Skolan/förskolan ser inte din individuella feedback, utan endast en summering av all feedback de fått.',
  feedbackIdeas: 'Synpunkter och idéer',
  feedbackIdeasDescription: 'Vi älskar att få feedback',
  feedbackIdeasDescriptionModal: 'Har du någon idé om hur vi kan göra appen och tjänsten bättre? Låt oss veta!',
  feedbackMissingContinueWorking:
    'Vänligen välj om du vill återvända till skolan igen, så att vi kan förbättra vår framtida matchning. Tack!',
  feedbackQuestion1: 'Lektionsplanering och \nuppdragsinformation',
  feedbackQuestion2: 'Mottagande på skolan',
  feedbackQuestion3: 'Struktur på arbetsplatsen',
  feedbackQuestion4: 'Elevernas bemötande',
  feedbackQuestion5: 'Kommer du ta fler uppdrag på skolan om möjligheten ges?',
  feedbackSubmitted: 'Tack för din feedback!',
  feedbackSummary: 'Vill du arbeta här fler gånger?',
  feedbackTitle: 'Hur var din dag?',
  fetchingError:
    'Vi ber om ursäkt, ett fel uppstod i vårt system. Vänligen försök igen. \n\nKontakta vår support om du behöver hjälp på en gång, eller om felet kvarstår.',
  fetchingErrorWithoutUser: 'Vi ber om ursäkt, ett fel uppstod i vårt system. Vänligen försök igen.',
  files: 'Filer',
  findJobs: 'Hitta Jobb',
  findMyNextJob: 'Hitta mitt nästa jobb!',
  finish: 'Avsluta',
  finishGuide: 'Avsluta guiden',
  firstName: 'Namn',
  flexible: 'Flexibel',
  flexibleStartTime: 'Detta uppdrag har flexibel starttid',
  flexibleStartTimeModalInfo:
    'Eftersom denna bokning startar %{startTime} är skolan okej med att du är lite sen. Tror du att du kommer hinna i tid?',
  flexibleTimeInfo:
    'Eftersom detta uppdrag börjar snart är skolan okej med att du är lite sen, så länge de vet när du förväntas vara där. I nästa steg får du ange en tid.',
  forgotPassword: 'Glömt lösenord?',
  frequentlyAskedQuestions: 'Vanliga frågor',
  from: 'från',
  fullDay: 'Heldag',
  fullBonusTermsAndConditions: 'Fullständiga villkor för bonusen',
  furtherInfoAboutExpressSupply: 'Mer info om att jobba som Expressvikarie',
  getNotifications: 'Få notiser om uppdrag',
  giveFeedback: 'Lämna feedback',
  giveItAll: 'Ge bort allt 💝',
  given: 'Gett Bort 💝',
  gladInterested:
    'Kul att du är intresserad! Vi kontaktar dig om du blir aktuell för uppdraget. (Kan du inte alla dagar eller är det något annat vi bör veta? Varför passar just du för detta uppdrag? Ange det nedan) *',
  gotIt: 'Tillbaka',
  goToRecruitment: 'Gå till Rekrytering',
  goToSettings: 'gå till Inställningar',
  grades: 'Årskurser',
  great: 'Toppen!',
  guide: 'Guide',
  guideTitle: 'Guide',
  guideSubtitle: 'Så fungerar appen!',
  hasBeenAdded: 'har lagts till i dina uppdrag',
  hi: 'Hej %{userName},',
  hide: 'Dölj',
  historicNotifications: 'Tidigare',
  history: 'Historik',
  historyEmpty: 'Du har inte genomfört ditt första jobb än',
  historyEmptyDescription: 'Dags att boka ditt första och påbörja din karriär med Humly',
  homeAddress: 'Adress',
  hour: 'timme',
  hourlyPay: 'Timlön',
  hours: 'timmar',
  hoursFirstLetterUp: 'Timmar',
  hoursIn: 'timmar under',
  hoursLabel: 'Arbetstid:',
  hoursPerWeek: 'timmar/vecka',
  hoursText: 'Timmar',
  hoursWithValue: 'Arbetstid: %{hours}',
  hoursPlural: {
    one: '%{count} timme',
    other: '%{count} timmar',
    zero: '%{count} timmar',
  },
  hoursShort: 'timme',
  howItWorks: 'Så funkar det',
  howItWorksChipText: 'Bjud in en vän eller fiende',
  howItWorksHeading: 'Här är allt du behöver göra!',
  howWasYourDay: 'Hur var din dag?',
  humlyAcademy: 'Humlys akademi',
  humlyAcademyDescription: 'Lär dig mer om lärarrollen',
  humlySupport: 'Humly Support',
  iAmSure: 'Jag är säker',
  ignore: 'Tacka nej',
  ignoreOfferTitle: 'Ta bort jobbet från listan?',
  ignoreOfferDescription:
    'Humly kommer meddelas att du inte är intresserad av det här jobbet, och det tas bort från listan.\n\nOm du är osäker kan du avbryta och använda knappen i övre vänstra hörnet för att gå tillbaka till listan utan att besluta nu. ',
  ignoreConfirmationButton: 'Ja, ta bort!',
  importantFiles: 'Viktiga dokument',
  inactiveUser: 'Inaktiv användare',
  inactiveUserDetails:
    'Din användare är inaktiverad. Förmodligen saknar du ett giltigt belastningsregister. Kontakta support för mer info. Tel: 010-49 89 950', // Feedback
  includeAddressForMatching: 'Inkludera min hemadress för matchning',
  includeRegionIsSearchLocation: 'Regionen du tillhör.',
  info: 'Info',
  informationMissing: 'Information saknas',
  initialPushNotification: 'Vi kan skicka pushnotiser till dig! 🎉',
  interest_accepted: '%{schoolName} vill ge dig uppdraget! Bekräfta omedelbart här i appen.',
  interest_rejected: '%{schoolName} valde tyvärr en annan person för jobbet. Tack för din ansökan.',
  interest_shown: 'Du gjorde en jobbansökan till uppdraget på %{schoolName}.',
  interested: 'Visat intresse',
  internalError: 'Problem med servern',
  internalErrorDescription: 'Ett fel uppstod.\nVänligen försök igen senare :)',
  invited: 'Inbjuden? Logga in',
  inviteFriend: 'Värva en vän',
  inviteOtherBrilliantEducatorsSubHeading: 'Bjud in andra briljanta utbildare!',
  inviteOtherBrilliantEducatorsText:
    'Dela din länk till så många du vill. Vi erbjuder 1000kr för varje vän (eller fiende).',
  inviteOtherBrilliantEducatorsText2: '',
  iosNotificationModalButton: 'Låter toppen!',
  iosNotificationModalDescription:
    'Vi har förbättrat våra pushnotiser som meddelar dig om spännande jobb. Efter detta kommer du bli frågad om att godkänna att Humly få skicka pushar till dig. Detta är en viktig del av appen, så vänligen godkänn detta. 🥳',
  iosNotificationModalTitle: 'Tillåt pushnotiser',
  iosRateUs: 'Ge oss fem stjärnor!',
  iUnderstand: 'Jag förstår!',
  job_appointed: 'Du blev tillsatt till ett uppdrag på %{schoolName}.',
  jobAcceptCheckboxLabel: 'Jag bekräftar att jag kan jobba hela bokningen.',
  jobAcceptExpressCheckboxLabel:
    'Jag bekräftar att jag kan jobba hela dagen och jag förstår att jag måste ta det uppdrag jag blir tilldelad av Humly.',
  jobAcceptTitle: 'Du är på väg att tacka ja till detta jobb:',
  jobApplyCheckboxLabel:
    'Jag bekräftar att jag kan jobba hela bokningen och jag förstår att jag måste jobba om jag får uppdraget.',
  jobApplyTitle: 'Du är på väg att ansöka till detta jobb:',
  jobDescription: 'Information',
  jobDetails: 'Uppdragsdetaljer',
  jobIsYours: 'Uppdraget är ditt! ✌️',
  jobOfferBreakTitle: 'Obetald rast',
  jobOfferContactDescription: 'Tveka inte att kontakta oss!',
  jobOfferContactTitle: 'Har du några frågor?',
  jobOfferHeaderAvailable: 'Ledigt Jobb',
  jobOfferHeaderCanceled: 'Avbokat Jobb',
  jobOfferHeaderConfirmed: 'Bekräftat jobb',
  jobOfferHeaderConfirmedExpress: 'Bekräftat express',
  jobOfferHeaderExpressAvailable: 'Ledigt Express Jobb',
  jobOfferHeaderNoAvailable: 'Inte längre tillgängligt',
  jobOfferHeaderPending: 'Pågående ansökan',
  jobOfferHeaderPendingExpress: 'Bekräfta express!',
  jobOfferHideBookings: 'Visa mindre',
  jobOfferIgnore: 'Nej tack!',
  jobOfferIgnoreDescription:
    'Humly kommer meddelas att du inte är intresserad av det här jobbet, och det tas bort från listan.\n\nOm du är osäker kan du avbryta och använda knappen i övre vänstra hörnet för att gå tillbaka till listan utan att besluta nu.',
  jobOfferIgnoreTitle: 'Ta bort jobbet från listan?',
  jobOfferInformationTitle: 'Information',
  jobOfferLunchTitle: 'Du äter lunch på plats',
  jobOfferPayTitle: 'Lön',
  jobOfferReplacementTitle: 'Ersatt pedagog',
  jobOfferRoomTitle: 'Avdelning',
  jobOfferRoutinesDescription: 'Viktig information om arbetsplatsen',
  jobOfferRoutinesTitle: 'Rutiner & information',
  jobOfferShowBookings: 'Visa alla dagar',
  jobOfferWithdrawButton: 'Ja, ta bort!',
  jobOfferWithdrawConfirmation: 'Är du säker på att du vill ta bort ditt intresse för det här jobbet?',
  jobOfferWithdrawTitle: 'Inte intresserad längre?',
  jobOfferWorkingDays: '%{days} Arbetsdagar',
  jobs: 'Uppdrag',
  jobsClose: 'Uppdrag nära dig',
  jobsListMatching: 'Jobb som Matchar Dina Preferenser ✅',
  jobsListMatchingEmpty:
    'Just nu finns det inga jobb som perfekt matchar dina preferenser och tillgänglighet. Men kanske något nästan perfekt? 👇',
  jobsListMoreOffersTitle: 'Fler Lediga Jobb - Ansök Nu',
  jobsListNotMatching: 'Andra jobb som kan passa dig?',
  jobsListRequestedTitle: 'Du har blivit Önskad! 💚 🦸',
  jobSubmitInterest: 'Ansök',
  jobSummary: 'Jobbsummering',
  landPhone: 'Landline Phone',
  lastName: 'Efternamn',
  lastYear: 'Förra året',
  leaveFeedback: 'Lämna feedback',
  legalNotices: 'Legal notices',
  legalNoticesDescription:
    'Denna applikation förlitar sig på olika bibliotek med öppen källkod i sin kodbas. Vi är tacksamma för bidrag från listan nedan.',
  level: 'Nivå',
  linkCopied: 'Länk kopierad!',
  loading: 'Hämtar...',
  loadingLevelsAndSubjects: 'Laddar årskurser och ämnen',
  loadMore: 'Ladda fler',
  login: 'Logga in',
  loginEmailError: 'Vi känner tyvärr inte igen den e-postadressen. Kontrollera den och prova igen.',
  loginError: 'Ogiltig e-post eller lösenord',
  loginHeader1: 'Upptäck',
  loginHeader2: ' jobb ',
  loginHeader3: 'inom\nskola och förskola',
  loginPasswordError:
    'Ojdå! Ditt lösenord verkar vara felaktigt. Testa gärna igen. Du kan återställa lösenordet via knappen nedan.',
  logOut: 'Logga Ut',
  long: 'Långa',
  longAssignment: 'Jag är intresserad av längre jobb som sträcker sig över flera veckor hos samma skola/förskola.',
  longTermFactTitle: 'Vad innebär Längre uppdrag?',
  longTermFactText1: 'Från ett par veckor upp till hela terminer.',
  longTermFactSubtext1: 'På en och samma skola/förskola.',
  longTermFactText2: 'Gedigen matchingsprocess',
  longTermFactSubtext2: 'Vi intervjuar löpande för att hitta den bästa matchningen för uppdraget.',
  longTermFactText3: 'Trygghet i att vara inbokad på uppdrag.',
  longTermFactSubtext3: 'Schema för hela uppdragsperioden i samband med bokningsbekräftelse.',
  longTermTitle: 'Just nu söker vi rätt person till dessa \nlängre uppdrag!',
  longTermOfferApplicationHeader: 'Ledigt längre uppdrag',
  longTermOfferPendingHeader: 'Pågående ansökan',
  longTermOpportunities: 'Längre uppdrag',
  longTermPending: 'Längre uppdrag du ansökt till',
  longTermPerm: 'Längre uppdrag',
  lunchPrice: 'Du äter lunch på skolan',
  lunchPriceText: 'Pris: %{lunchPrice} som vi drar på lönen',
  makeChanges: 'Redigera',
  makeDifference: 'Gör skillnad',
  markAsRead: 'Markera som läst',
  matching: 'Matchar',
  matchingInfoTooltip: 'Baseras på din tillgänlighet, geografisk matchning, årskurser och ämnen.',
  menu: 'Meny',
  message: 'Meddelande',
  messageFromStudentvikarie: 'Meddelande från Humly!',
  messages: 'Meddelanden',
  messagesSubtitle: 'Kommer snart!',
  minutes: 'minuter',
  missingFields: 'Saknad info:',
  missingPreferencesDetails: 'Vi saknar nedan information',
  missingProfileDetails: 'Vi saknar några profiluppgifter',
  missingProfilePreferencesDetails: 'Vi saknar nedan information',
  modalWeakConnectionMessage: 'Din internetuppkoppling är svag. Vänligen försök igen.',
  moneyRiseInfo: '+ %{money} sen förra månaden',
  month: 'Månad',
  more: 'mer',
  multiDay: 'Flerdagar',
  must: 'SKYLDIG',
  myEarnings: 'Min lön',
  myEarningsDescription:
    'Dessa summor är uppskattningar och kan därför komma att skilja sig från din faktiska lön. Du kommer kunna se detaljer kring din lön i din lönespecifikation som kommer i din digitala brevlåda i slutet av varje månad.',
  name: 'Namn',
  negotiable: 'Enligt överenskommelse',
  newMessage: 'Nytt meddelande',
  newNotifications: 'Nytt',
  newPassword: 'Nytt lösenord',
  newVersion: 'Ny appversion tillgänglig!',
  newVersionDescription: 'Uppdatera appen nu för att få tillgång till alla nyheter och förbättringar.',
  next: 'Nästa',
  no: 'Nej',
  noAccount: 'Har du inget konto?',
  noAdded: 'Kan ej arbeta',
  noAreas:
    'Du matchas och får notiser från alla skolor i %{regionName}. Lägg till ett område för att bara matchas med skolor nära dig.',
  noAvailableAssignments: 'Du har inga jobbansökningar',
  noAvailableAssignmentsDescription: 'Kanske är det dags att leta efter en ny \njobbmöjlighet?',
  noAvailableProposals: 'Inga jobberbjudanden just nu',
  noAvailableProposalsDescription: 'Kanske finns det något kortare uppdrag som kan vara av intresse istället?',
  noAvailableRequests:
    'Just nu finns det tyvärr inga jobbförfrågningar som matchar din profil. Se över din tillgänglighet för att få en notis så fort ett nytt jobb blir tillgängligt.',
  noMatchingBookings: 'Tyvärr har vi inte har några uppdrag som matchar din tillgänglighet och preferenser just nu.',
  noMessages: 'Här kan du se meddelanden från Humly. Just nu har du inga meddelanden i din inkorg',
  noCompany: 'INGET FÖRETAG',
  noNotificationsDescription: 'Vi meddelar dig när det hänt något \nnytt att hålla koll på.',
  noNotificationsTitle: 'Allt under kontroll!',
  noRegion: 'INGEN REGION',
  noSchedule: 'Jag har inte en annan huvudsaklig sysselsättning och vill välja mina egna uppdrag genom appen.',
  noSuggestions: 'Inga förslag',
  notAvailable: 'Kan ej arbeta',
  noAvailable: 'Inte tillgänglig',
  notCompleteAssignments: 'Du har inga jobbade uppdrag denna månad.',
  notificationError:
    'Vi kan tyvärr inte skicka pushnotiser till din telefon. Försök att återställa dina pushnotiser på din profil här i appen. Tack!',
  notifications: 'Notiser',
  notificationsAppointedTitle: 'Du fick jobbet!',
  notificationsAppointedSubTitle: 'Du blev tillsatt till ett uppdrag på %{school}',
  notificationsAcceptedTitle: 'Du gjorde en jobbansökan!',
  notificationsAcceptedSubTitle: 'Du gjorde en jobbansökan till uppdraget på %{school}',
  notificationDeletingError: 'Radering misslyckades, försök igen',
  notificationsModalCancel: 'Avbryt',
  notificationsModalDelete: 'Radera denna notis',
  notificationsModalMore: 'Visa mer',
  notificationsModalRead: 'Markera som läst',
  notificationsModalUnRead: 'Markera som läst',
  notificationsCampaignLink: 'Öppna länk',
  notificationsCampaignTitle: 'Humly skrev till dig',
  notificationsShownTitle: 'Bekräfta din jobbansökan!',
  notificationsShownSubTitle: '%{school} vill ge dig uppdraget! Bekräfta omedelbart här i appen.',
  notificationsStatusChangeError: 'Ändring misslyckades, försök igen',
  notificationsRejectedTitle: 'Uppdrag inte längre tillgängligt',
  notificationsRejectedSubTitle:
    'Uppdraget på %{school} är tyvärr inte längre tillgängligt. Vänligen kika i appen efter andra möjligheter.',
  notificationsProposalClosedTitle: 'Längre uppdrag inte längre tillgängligt',
  notificationsProposalClosedSubTitle:
    'Ett längre uppdrag som du ansökte till är inte längre tillgängligt. Klicka här för att läsa mer.',
  notWorkedOvertime: 'Ja! Checka ut',
  noUpcomingBookings: 'Inga kommande jobb…',
  noUpcomingBookingsDescription:
    'Så vill du inte ha det, hoppa över till Hitta jobb \nfliken istället och skaffa jobben du vill ha!',
  numberOfClientsInArea: '%{quantity} skolor och förskolor inom ditt område',
  numberOfClientsInAreaTooltip: 'Du kommer få notiser om matchande jobb inom ditt valda område.',
  occasionalWork: 'Kortare tillfälliga uppdrag',
  offerNotAvailable: 'Jobbet är inte längre tillgängligt.',
  offerNotAvailableDetails:
    'Detta jobb är inte längre tillgängligt. Tack för din ansökan. Gå till ‘Hitta jobb’ för att se fler lediga jobb.',
  offerNotAvailableTitle: 'Inte längre tillgängligt',
  OK: 'OK',
  okGo: 'Okej, kör!',
  oldPassword: 'Nuvarande lösenord',
  onHold: 'Pausat konto',
  onWhichLevel: 'Vilka årskurser vill du undervisa?',
  onWhichSubjects: 'Ämnen du skulle vilja undervisa',
  open: 'Öppet',
  openGuide: 'Kort om hur appen fungerar',
  openHours: 'Öppet %{hours}',
  openingHours: 'Våra öppettider',
  openLink: 'Gå vidare',
  openMap: 'Öppna karta',
  openSettings: 'Inställningar',
  openSourceInfo: 'Information om öppen källkod', // Validation
  order: 'Flera',
  orderBookingInfo: 'OBS! Uppdraget är en del av ett flerdagars-uppdrag.',
  orderBookingsSubtitle:
    'Detta jobb består av följande dagar och tider. Du måste kunna arbeta alla dagar för att söka detta jobb.',
  orderIncludeInfo: 'Detta flerdagars-uppdrag innehåller följande dagar och tider',
  orderNotice: 'OBS! Du måste kunna arbeta alla dessa pass för att tacka ja till uppdraget.',
  orderSalaryFullDay: '%{salary}',
  orderSalaryAM: '%{salary}',
  orderTitle: 'Flerdagarsuppdrag',
  other: 'Andra',
  otherAddresses: 'Andra adresser',
  otherJobs: 'Andra lediga uppdrag',
  otherPlans: 'Jag kan inte arbeta uppdragets tider',
  otherRoutines: 'Andra viktiga rutiner',
  overtimeApprovingPersonLabel: 'Vem på skolan godkände övertiden?',
  overtimeDatePickerLabel: 'Vilken tid avslutades ditt arbetspass?',
  overtimeExplanationLabel: 'Vad gjorde du under din övertid?',
  overtimeInfo:
    'Tänk på att du måste ha jobbat minst 15 minuter övertid för att ha rätt att rapportera övertid. Besvara frågorna nedan för att förtydliga din tidrapport. Skolan kommer granska din tidrapport.',
  password: 'Lösenord',
  passwordConfirmation: 'Bekräfta nytt lösenord',
  pay: 'Lön',
  payByEstimatedPeriod: 'Din estimerade lön för perioden är %{payRate} kronor 👏',
  payByPeriod: 'Din estimerade lön för perioden var %{payRate} 🏆',
  payByPeriodDescription:
    'Dessa siffror är en uppskattning av din lön inklusive 13% semesterersättning och före skatt. Du får betalt den 25e månaden efter att du arbetade.',
  payRate: 'Lön:',
  payslip: 'Lönespecifikation',
  payslips: 'Lön',
  pendingInterests: 'Pågående Ansökningar',
  perHour: 'per timme',
  permanentVacancy: 'Anställd av skolan',
  permissionDenied: 'Åtkomst nekad',
  permissionDeniedInfo: 'För att kunna ta bilder med din kamera och välja bilder från ditt bibliotek.',
  phone: 'Telefon',
  phoneCopied: 'Telefonnummer har kopierats ',
  phoneError: 'Ange ett giltigt telefonnummer',
  pinCodeDescription:
    'Vill skolan att du kommer tillbaka en dag till? Använd denna kod för att godkänna att skolan förlänger ditt uppdrag.',
  pinCodeRedeemedBy: 'Bokades med Personlig bokningskod',
  pinCodeTitle: 'Personlig bokningskod',
  pleaseTryAgain: 'Var god försök igen :)',
  PM: 'EM',
  preferences: 'Preferenser',
  preparationTime: 'Förberedelsetid',
  prev: 'Förra',
  previous: 'Tidigare',
  primarySchool: 'Grundskola',
  profile: 'Profil',
  profileDescription: 'Profilbeskrivning',
  profileEducation: 'Utbildning',
  profileImageWarning:
    'Din profilbild kommer visas för skolor, så välj en professionell bild. Bilden ska kunna representera dig när du sedan är ute på uppdrag. Undvik även alla typer av filter. Tack. :-)',
  progress: 'Status',
  proposal: 'Intresseanmälan',
  proposal_interest_accepted: 'Skola vill ge dig uppdraget! Bekräfta omedelbart här i appen.',
  proposal_interest_rejected: 'Skola valde tyvärr en annan person för jobbet. Tack för din ansökan.',
  proposal_interest_shown: 'Du gjorde en jobbansökan till uppdraget.',
  proposal_job_appointed: 'Du blev tillsatt till ett uppdrag.',
  proposals: 'Gör en intressanmälan - Längre uppdrag',
  pushNotificationsError:
    'Ett fel uppstod när vi skulle kontrollera dina push-notiser. Vänligen kontrollera att du tillåtit Humly appen att skicka notiser till dig i dina telefon-inställningar. Om detta inte hjälper kan du prova att återställa dina push-notiser nedan.',
  pushNotificationsNoPermissions:
    'Vi kan inte skicka pushnotiser till din telefon. Du måste gå till din telefons inställningar och tillåta notiser från Humly-appen.',
  pushNotificationsNotPermitted:
    'Vänligen kontrollera att du tillåtit Humly appen att skicka notiser till dig i dina telefon-inställningar. Om detta inte hjälper kan du prova att återställa dina push-notiser nedan.',
  pushNotificationsPermitted: 'Vi kan skicka push-notiser till dig!',
  pushNotificationsSettings: 'Inställningar push-notiser',
  pushNotificationsStatus: 'Status för push-notiser:',
  quitFormNO: 'Avbryt',
  quitFormOK: 'Lämna sidan',
  quitFormTitle: 'Lämna sidan utan att spara?',
  quitFormTitleDescription:
    'Du har ändringar som ännu inte sparats. Alla osparade ändringar kommer försvinna om du lämnar sidan nu. \nÄr du säker på att du vill lämna sidan utan att spara? ',
  quitGuide: 'Avsluta guide',
  rate: 'h/vecka',
  rateUs: 'Gillar du vår app?',
  rateUsStore: 'Ge oss fem stjärnor i App Store',
  readMore: 'Läs mer',
  realIndependence: 'Äkta frihet',
  recruitment: 'Rekrytering',
  referralAnnouncementBodyPartOne:
    'Bjud in andra briljanta utbildare! Och när de ansluter till Humly väljer du hur bonusen ska betalas ut. Ge bort allt? Dela lika 50/50? Eller behålla allt för dig själv?',
  referralAnnouncementBodyPartTwo:
    'När de jobbar sin första dag betalar vi bonusen vid nästkommande lönutbetalning. Håll koll på hur mycket du gett bort och tjänat in och se om du är en riktig vän eller fiende 😈',
  referralAnnouncementOverline: 'Gillar du att få pengar?',
  referralAnnouncementPrimaryActionTitle: 'Börja bjud in',
  referralAnnouncementTitle: 'Bjud in en vän (eller fiende)',
  referralAppliedInfo: 'Ansökte: %{date}',
  referralBonusNeedsToMeetConditions:
    'För att du ska få din tipsbonus utbetald måste du och din vän uppfylla alla dessa villkor:',
  referralsErrorMessage: 'Vi misslyckades med att hämta dina inbjudningar. Försök gärna igen.',
  referralPaidOutInfo: 'Utbetald: %{date}',
  referralSectionEducatorAccepted: 'Godkänd & redo att jobba',
  referralSectionEducatorInRecruitment: 'Rekrytering pågår',
  referralSectionEducatorInRecruitmentInfo: 'Välj hur bonusen ska betalas ut',
  referralSectionEducatorLeftHumly: 'Har Lämnat Humly',
  referralSectionPaidOut: 'Bonus utbetald',
  referrals: 'Bjud in en vän eller fiende',
  referralShareInfo:
    'Hej, jag skulle vilja berätta om Humly. De ger briljanta utbildare full kontroll över sin karriär. Och om du går med och jobbar en dag, kommer de att betala en bonus på upp till 1000 kr vid nästa lön! Gå med här: %{link}',
  referralShareInfoTitle: 'Har du hört talats om Humly?',
  referralsInfo: 'När du bjuder in en vän eller fiende bestämmer du hur bonusen på 1000 kr ska betalas ut.',
  referralsMenuInfo: 'Upp till 1000kr för varje inbjudan',
  referralsModalTitle: 'Hur vill du att bonusen på 1000 kr ska betalas ut till %{name}?',
  referralsSubInfo:
    'Ge bort den? Dela den? Behåll allt för dig själv?\nGör valet när din vän (eller fiende) ansökt till Humly.',
  reject: 'Neka',
  rejectedDescription: 'Tyvärr, skolan har valt att tillsätta någon annan. Fortsätt visa intresse för fler uppdrag!',
  rejectedIcon: 'Nekad',
  rejectedTitle: 'Skolan har valt att tillsätta en annan vikarie',
  rejectJob: 'Tacka nej',
  rejectModalTitle: 'Hjälp oss ge dig bättre matchningar - varför tackade du nej till detta uppdrag?',
  rejectProposal: 'Nej tack!',
  relationship: 'Anhörigs relation till dig',
  relativeName: 'Anhörigs namn',
  relativeNameDescription:
    'För säkerhets skull behöver vi ha kontaktuppgifter till någon av dina anhöriga. Vi kommer endast kontakta personen om du skulle råka ut för något.',
  relativePhone: 'Anhörigs telefonnummer',
  remove: 'Ta bort',
  removeAddressWarning: 'Är du säker på att du vill ta bort området?',
  removeAvailabilityWeek: 'Ta bort tillgänglighet denna vecka',
  report: 'Rapportera',
  reported: 'Färdiga',
  reportError:
    'Vi ber om ursäkt, ett fel uppstod i vårt system. Vänligen försök igen. \n\nKontakta vår support om du behöver hjälp på en gång, eller om felet kvarstår.',
  reportTime: 'Bekräfta tidrapport',
  reportTimeBreak: 'Hur lång rast hade du?',
  reportTimeEnd: 'När slutade din dag?',
  reportTimeEndError: 'Sluttiden kan inte vara senare än starttiden.',
  reportTimeModified: 'Uppdaterat av dig',
  reportTimeOvertimeReason: 'Vad gjorde du under din övertid?',
  reportTimeOvertimeRequestBy: 'Vem godkände din övertid?',
  reportTimeStart: 'När började du arbeta?',
  reportTimeStartError: 'Starttiden kan inte vara senare än sluttiden.',
  reportTimeTitle: 'Ändra dina tider',
  reportTokenOutdatedDescription:
    'Din aktiva session har avslutats eftersom du inte varit aktiv på ett tag. Vänligen logga in igen 😊',
  reportTokenOutdatedTitle: 'Du har blivit utloggad',
  requested_by_school: 'Du blev önskad till ett jobb',
  requiredField: 'Detta är ett obligatorisk fält',
  requiredFields: 'Vänligen fyll i alla obligatoriska fält',
  resetPassword: 'Återställ lösenord',
  resetPasswordButton: 'Logga in på mitt konto',
  resetPasswordConfirmationHeader: 'Kolla din inkorg!',
  resetPasswordHeader: 'Glömt lösenord?',
  resetPasswordInfo: 'Inga bekymmer, vi skickar instruktioner\nom hur du återställer det.',
  resetPasswordBackButton: 'Tillbaka till inloggning',
  resetPasswordConfirmation:
    'Ditt lösenord har återställts!\n\nVi har skickat ett tillfälligt lösenord och ytterligare instruktioner till dig. Dubbelkolla gärna i eventuell skräppostmapp om du inte hittar mejlet i din inkorg.',
  resetPasswordError:
    'Vi kunde inte hitta ett konto med den e-postadressen. Vänliigen kontrollera och försök igen, eller kontakta vår support för vidare hjälp.',
  resetPushToken: 'Återställ mina push-notiser', //Addresses&Location
  retry: 'försök igen',
  required: 'Obligatorisk',
  routines: 'Rutiner',
  salary: 'Lön',
  salaryLabel: 'Lön:',
  salaryInfo:
    'Här kan du se dina historiska uppdrag. Även avbokade uppdrag visas här, så antalet timmar som du faktiskt får lön för kan skilja sig från dessa siffror.',
  salaryView: 'Lön',
  save: 'Spara',
  saveChanges: 'Spara ändringar',
  saveLocation: 'Spara område',
  schedule: 'Schema',
  school: 'Skola',
  schoolCanBookYou: 'Skolor du valt (under Skolor) får boka dig direkt dessa tider utan att du tackar ja.',
  schoolCancelledJob: 'Skolan har avbokat detta uppdrag',
  schoolChosenElse: 'Jobbet är inte längre tillgängligt.',
  schoolListDescription:
    'De enheter du valt bort kommer inte kunna boka dig igen och du kommer inte längre se lediga uppdrag från dem. \n\nDet är bara du själv, och vi på Humly som kan se vilka arbetsplatser du valt bort. Skolan/Förskolan får inte ta del av denna information. \n\nOm det var något särskilt på arbetsplatsen som gjorde att du valde bort den uppskattar vi om du hör av dig till oss. Detta för att vi ska kunna uppmärksamma problemen och förhoppningsvis bidra till en bättre arbetsmiljö framöver. ',
  schoolListInfo: 'Ställ in vilka tidigare arbetsplatser du vill fortsätta matchas med. ',
  schoolListKeepMatching: 'Fortsätt matcha med',
  schools: 'Skolor',
  searchDistance: 'Sökavstånd - Hur långt vill du resa?',
  secondarySchool: 'Gymnasiet',
  seeVacancies: 'Se tillgängliga jobb',
  select: 'Välj',
  selectAvatar: 'Välj profilbild',
  selectionNoLongerAvailable: 'Inte tillgängligt att göra ett val',
  send: 'Skicka',
  serverMaintenanceWarningDescription:
    'Vi utför för närvarande nödvändigt underhåll på vår tjänst. Detta underhåll är nödvändigt för att säkerställa fortsatt optimal prestanda och säkerhet. Vi förväntar oss att underhållet kommer vara slutfört %{migrationEstimatedTime}.\n\nSom ett resultat av detta underhåll kommer Humly appen att vara tillfälligt otillgänglig. Vi beklagar eventuella besvär detta kan orsaka och uppskattar din förståelse.',
  serverMaintenanceWarningTitle: 'Appen är inte tillgänglig just nu',
  serverUnavailable:
    'Vänligen försök igen om fem minuter. Kontakta oss via telefon på 010 498 99 50 eller e-post via support@humly.io om problemet kvarstår och du behöver snabb hjälp.',
  serverUnavailableTitle: 'Vår server ligger tillfälligt nere.',
  setAvailability: 'Lägg till tillgänglighet',
  setPhoto: 'Välj profilbild',
  share: 'Dela',
  short: 'Förfrågningar',
  shortTermPending: 'Korta uppdrag du ansökt till',
  show: 'Visa',
  showBooking: 'Visa bokning',
  showLess: 'visa mindre',
  showMoreMonths: 'Visa fler månader...',
  shownInterest: 'Du visade intresse för detta uppdrag',
  showPasswords: 'Visa lösenord',
  simple: 'En dag',
  sinceStarting: 'Sedan start',
  skip: 'Skippa',
  smartTechnology: 'Smart teknik',
  smsMessage: 'Skickat som SMS',
  snackbarAppointedMessage: 'Yay! Uppdraget är ditt.   ',
  snackbarDisconnectedMessage: 'Din telefon är inte ansluten till Internet.',
  somethingWentWrong: 'Något gick fel...',
  splitEvenly: 'Dela 50/50 😇',
  startTime: 'Starttid',
  stateContactTitle: 'Några frågor? Tveka inte att kontakta oss!',
  stepsDescription: 'Steg till att börja ta uppdrag:',
  street: 'Gata',
  studentvikarieContactAppointedTitle: 'Sjuk? Andra förhinder/frågor? Kontakta Humly',
  studentvikarieContactButtonText: 'Kontakta oss',
  studentvikarieContactTitle: 'Frågor? Kontakta Humly',
  studentvikarieProposalContactTitle: 'Har du några frågor? Kontakta oss!',
  subject: 'Ämne',
  subjects: 'Ämnen',
  submitFeedback: 'Skicka feedback', //Push notification
  submitTimesheet: 'Bekräfta Tidrapport',
  successfullyEndedOnHoldState: 'Ditt konto är aktiverat.',
  summary: 'Översikt',
  support: 'Support',
  supportCommonQuestions: 'Vanliga frågor',
  supportEmail: 'support@humly.io',
  supportHelpNote:
    'Vikariesupporten hjälper dig med alla frågor du har gällande ett specifikt uppdrag eller din anställning.',
  supportHours: 'Måndag - torsdag 06-18, fredag 06-17',
  supportHoursTitle: 'Vi har öppet',
  supportPhone: '010-498 99 50',
  supportTitle: 'Hur kan vi hjälpa dig?',
  sweden: 'Sverige',
  takeItAll: 'Behåll allt 😈',
  takePhoto: 'Ta ett foto...',
  takeToSeparatePage:
    'Din lönespecifikation skickas till din digitala brevlåda i Kivra. Siffrorna i din officiella lönespecifikation kommer visa lönen efter skatt.',
  teacherReplaced: 'Ersatt pedagog',
  temporaryLongTermBooking: 'Längre uppdrag',
  termsAgreement: 'Godkänn villkoren',
  termsAndConditions: 'Fullständiga villkor',
  thankYou: 'Tack för din input! 🙌🏽',
  theBonusIsTaxed: 'Bonusen beskattas som arbetsinkomst med samma skattesats.',
  thisHappensNow: 'Detta händer nu',
  thisYear: 'Detta år',
  time: 'Tid',
  timeFormat: 'H:mm',
  timeMustBeAfter: 'Time must start at %{minimumDate}',
  timeMustBeBefore: 'Time must start before %{maximumDate}',
  timeMustBeBetween: 'Time must be between %{minimumDate} and %{maximumDate}',
  timesheet: 'Tidrapport',
  timesheetBackModalDescription: 'Ändringarna du har gjort kommer inte sparas.',
  timesheetBackModalTitle: 'Vill du lämna sidan?',
  timesheetMenu: 'Tidrapportering',
  timesheetReport: 'Rapportera',
  timesheetReported: 'Klar',
  title: 'Titel',
  titleEx: "Titel - Exempelvis 'Hem'",
  titleWelcome: 'Välkommen!',
  to: 'till',
  toBeDecided: 'Ej fastställt',
  today: 'Idag',
  todaysReplies: 'Dagens besked',
  toFewHours: 'För få timmar',
  tomorrow: 'I morgon',
  tonight: 'I kväll',
  tooLongTravel: 'För lång restid',
  toPreferences: 'Till Preferenser',
  total: 'Totalt',
  totalTime: '%{hours} timmar, %{minutes} minuter',
  totalTimeCommon: '%{hours} h %{minutes} m',
  totalTimeShort: '%{hours} h %{minutes} min',
  tutorFeedbackFormHelperText: 'Vänligen fyll i alla fält markerade med stjärna (*) för att kunna lämna in formuläret.',
  tutoringReport: 'Handledningsrapport',
  typeMessage: 'Skriv ett meddelande',
  unitedKingdom: 'United Kingdom',
  unpaidTime: 'Obetald rast',
  unpaidTimeInfo: 'Total rast under dagen',
  unreported: 'Kvar att rapportera',
  upcoming: 'Kommande',
  update: 'Update',
  updateNow: 'Uppdatera nu!',
  updatePreferencesCampaignDescription:
    'Är du intresserad av att boka ett längre uppdrag kommande termin? \n\nKommer ditt nuvarande schema att ändras? Vill du ha fler timmar eller färre? \n\nUppdatera dina jobbinställningar så att vi kan matcha dig med rätt uppdrag nästa termin.',
  updatePreferencesCampaignTitle: 'Se över dina jobbinställningar för kommande termin 👇',
  uploadError: 'Fel vid överföring av fil',
  upToAmountOnEveryInvite: 'Upp till 1000kr för varje inbjudan',
  useForMatching: 'Använd för matchning',
  validationError: 'Fyll i alla nödvändiga fält',
  version: 'Appversion',
  viewAll: 'Visa alla',
  viewProfile: 'Visa profil',
  waitForResonse: 'Inväntar svar.',
  waitingForConfirmation: 'Läs och bekräfta så snabbt som möjligt',
  waitingForResonse: 'Väntar på besked',
  waitingForResponseTitle: 'Väntar på besked från skolan...',
  waitingIcon: 'Inväntar besked',
  weAreSorryCancelled:
    'Vi är ledsna men skolan har avbokat detta uppdrag. Försök hitta ett nytt eller kontakta oss så hjälper vi dig!',
  webRateUs: 'Ge oss fem stjärnor!',
  week: 'vecka',
  welcome: 'Välkommen',
  welcomeDescription1:
    'Välkommen till en helt ny jobb-värld! Hos oss styr du själv ditt arbete direkt i appen. Glöm tjatiga chefer och upptäck äkta frihet.',
  welcomeDescription2:
    'Ingen ledarskapsutbildning i världen kan slå erfarenheten du får av att leda och inspirera en skolklass. Vi utbildar och coachar dig på din resa som flexibel utbildare.',
  welcomeDescription3:
    'Få saker ger så mycket energi som att ge elever aha-upplevelser och nya perspektiv. Ta chansen att bli en positiv kraft i den svenska skolan.',
  welcomeDescription4:
    'Fyll i dina önskemål och få notiser i telefonen när det kommer in uppdrag hos våra samarbetsskolor som passar dig. Smidigt och enkelt!',
  willMakeInTime: 'Jag kommer hinna i tid.',
  withoutBreakTime: 'Ingen rast',
  workAtleastOnce: 'Din vän måste ha arbetat minst en gång.',
  workedOvertime: 'Nej, jag arbetade övertid',
  workFirstGetBonusAfterSubHeading: 'När de jobbat sin första dag betalar vi bonusen vid nästa löneutbetalning.',
  workFirstGetBonusAfterText: '(Observera att du själv måste  också ha arbetat minst en dag för att få bonusen)',
  workPreferences: 'Jobbinställningar',
  writeMessage: 'Skriv ett meddelande till oss',
  wrongLevels: 'Fel årskurser',
  wrongSubjects: 'Fel ämnen',
  yes: 'Ja',
  year: 'År',
  youAreRequested: 'Du har blivit önskad till detta jobb',
  youAreRequestedTooltip: 'Skolor/förskolor kan önska flera personer. Den första att acceptera uppdraget får det.',
  youCanStillInvite:
    'Du måste ha arbetat minst en gång. Notera att du kan bjuda in kompisar innan du har börjat arbeta. Första gången du arbetar får du potentiella upparbetade bonusar utbetalda.',
  youDecideYourCut:
    'Du avgör själv om du vill dela bonusen med din vän, om du vill ta hela bonusen eller om du vill ge bort hela bonusen. Detta går att välja fram till dess att din vän har blivit tilldelad sitt första uppdrag. Det går inte att ändra detta i efterhand.',
  youLeftFeedback: 'Tack för din feedback!',
  youMustBeHired: 'Du måste fortfarande vara anställd vid tillfället din vän börjar arbeta och bonusen ska betalas ut.',
  yourAreas: 'Dina områden',
  yourAreasDescription: 'Du matchas med skolor i dessa områden',
  yourCompletedJobs: 'Dina Genomförda Jobb',
  yourJobEndedAt: 'Ditt uppdrag slutade %{time} idag. Stämmer det?',
  yourReferrals: 'Dina inbjudningar',
  yours: 'Dina uppdrag',
  youWorked: 'Du har jobbat',
  zip: 'Postnummer',
  zipCodeError: 'Postnummer måste vara femsiffrigt',
  zipEx: 'Postnummer- Fem siffror',
  thankYouForApplyingDescription:
    'Vi kommer kolla igenom din ansökan och återkommer sedan med besked om nästa steg i rekryteringsprocessen. Hör gärna av dig om du har några frågor under tiden!',

  ...onboardingSv,
}
