import { StyleSheet, ViewStyle } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

const touchContainer = {
  borderRadius: 4,
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  divider: {
    borderBottomColor: colorsNew.redesign.other.divider,
    borderBottomWidth: 1,
    marginBottom: paddingNew.base,
  },
  iconClosed: {
    transform: [{ rotate: `90deg` }],
  },
  iconColor: {
    color: colorsNew.redesign.action.active,
  },
  iconOpen: {
    transform: [{ rotate: `-90deg` }],
  },
  info: {
    ...typography.caption,
    color: colorsNew.redesign.text.secondary,
    marginTop: paddingNew.xxs,
  },
  referralsWrapper: {
    gap: paddingNew.sm,
    marginBottom: paddingNew.base,
    marginTop: paddingNew.sm,
  },
  title: {
    ...typography.overline,
    color: colorsNew.redesign.text.primary,
    lineHeight: 16,
  },
  titleIconWrapper: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  spaceBottom: {
    marginBottom: paddingNew.sm,
  },
  touchPressed: {
    ...touchContainer,
    backgroundColor: colorsNew.redesign.action.pressed,
  } as ViewStyle,
  touchUnpressed: {
    ...touchContainer,
  } as ViewStyle,
})

export default styles
