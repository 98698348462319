export type FeatureFlagsT = {
  expressInTheUK: boolean
  release_refer_a_friend_240828: boolean
}

export type FeatureFlagStateT = {
  featureFlags: FeatureFlagsT
}

export enum FeatureFlags {
  EXPRESS_UK_FEATURE_TOGGLE = 'expressInTheUK',
  REFER_A_FRIEND_FEATURE_TOGGLE = 'release_refer_a_friend_240828',
}

export const flagKeys = [FeatureFlags.REFER_A_FRIEND_FEATURE_TOGGLE, FeatureFlags.EXPRESS_UK_FEATURE_TOGGLE]
