import React, { ReactNode, useState } from 'react'
import get from 'lodash/get'
import find from 'lodash/find'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import Select from 'react-select'

import { Label, Error, TouchableContent } from 'src/components/Form/Common'
import { colors, colorsNew } from 'src/styles'
import { PropsT } from './types'
import styles from './styles'

const getSelectedField = (fieldValue: string, values: PropsT<string>['values']) =>
  find(values, _value => _value?.value === fieldValue)

const Menu = (props: any) => <View style={styles.menuStyle} {...props} />

const Dropdown = ({ children, isOpen, target }: { children: ReactNode; isOpen: boolean; target: ReactNode }) => (
  <View>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
  </View>
)

const Option = ({
  innerProps,
  children,
  isSelected,
}: {
  innerProps: object
  children: ReactNode
  isSelected: boolean
}) => (
  <TouchableOpacity
    style={{
      backgroundColor: isSelected ? colors.successLight : colors.white,
      color: isSelected ? colorsNew.redesign.text.primary : colorsNew.redesign.text.primary,
    }}
  >
    <View {...innerProps} style={styles.optionRow}>
      <View style={styles.circleContainer}>{isSelected && <View style={styles.circleFill} />}</View>
      <Text>{children}</Text>
    </View>
  </TouchableOpacity>
)

const InputSelectModal = <Type extends string>(props: PropsT<Type>) => {
  const {
    containerStyles,
    field: { name },
    form: { errors, touched, setFieldValue, setFieldTouched },
    label,
    labelStyles,
    required,
    values = [],
    placeholder,
    isSearchable = true,
    onChange,
  } = props
  const fieldValue = props?.field?.value
  const selectedField = getSelectedField(fieldValue, values)
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState(selectedField)

  const onSelectChange = (valueChanged: PropsT<Type>['values'][0]) => {
    setFieldTouched(name)
    setIsOpen(val => !val)
    setValue(valueChanged)
    setFieldValue(name, valueChanged.value)
    onChange && onChange(valueChanged.value)
  }

  const selectStyles = {
    control: (provided: object) => ({
      ...provided,
      marginTop: 0,
      minWidth: 240,
      margin: '20px 22px 8px 22px',
      display: isSearchable ? 'block' : 'none',
    }),
    option: (provided: object, { isSelected }: { isSelected: boolean }) => ({
      ...provided,
      padding: '20px 35px',
      backgroundColor: isSelected ? colors.primary : colors.white,
      color: isSelected ? colorsNew.redesign.text.primary : colorsNew.redesign.text.primary,
    }),
    menu: () => ({
      paddingBottom: 4,
      paddingTop: 4,
    }),
    menuList: (provided: object) => ({
      ...provided,
    }),
  }

  return (
    <>
      <View>
        <Dropdown
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          target={
            <View style={StyleSheet.flatten([styles.container, containerStyles])}>
              <Label labelStyles={labelStyles} label={label} required={required} />
              <TouchableContent
                fieldValue={fieldValue}
                fieldLabel={selectedField?.label || placeholder}
                hasError={hasError}
                onPress={() => setIsOpen(val => !val)}
              />
              <Error errors={isTouched ? errors : {}} name={name} />
            </View>
          }
        >
          <Select
            autoFocus
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            closeMenuOnSelect={true}
            isClearable={false}
            components={{
              IndicatorSeparator: null,
              DropdownIndicator: null,
              Option,
            }}
            onBlur={() => setIsOpen(val => !val)}
            menuIsOpen={isOpen}
            onChange={val => onSelectChange(val)}
            options={values}
            placeholder="Search..."
            styles={selectStyles}
            tabSelectsValue={false}
            value={value}
            theme={theme => ({
              ...theme,
              // This line disable the blue border
              border: 0,
              boxShadow: 'none',
              colors: {
                ...theme.colors,
                primary: colors.primary,
                primary50: colors.primary,
              },
            })}
          />
        </Dropdown>
      </View>
    </>
  )
}

export default InputSelectModal
