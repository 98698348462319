import gql from 'graphql-tag'

export default gql`
  mutation Mutation($update: updateReferralsTermsAndConditionsInput!) {
    updateReferralsTermsAndConditions(update: $update) {
      acceptedReferralsTermsAndConditions
    }
  }
`

export type updateReferralsTermsAndConditionsInput = {
  update: {
    educatorId: string
    value: boolean
  }
}
