import React from 'react'
import { Formik } from 'formik'
import { Text, View } from 'react-native'
import invert from 'lodash/invert'

import { bonusShareRatioToVariant } from 'src/scenes/Main/ReferAFriendOrFoe/ReferralsList/constants'
import { getReferralModalOptions } from './utils'
import { ReferChipVariant } from 'src/scenes/Main/ReferAFriendOrFoe/components/ReferChip/types'
import ButtonNew, { buttonSize, buttonVariants } from 'src/components/Buttons/ButtonNew'
import InputRadioGroup from 'src/components/Form/InputRadioGroup/InputRadioGroupNew'
import NewModalWrapper from 'src/components/Modal/NewModal/NewModalWrapper'
import styles from './styles'
import translations, { translate } from 'src/utils/translations/translations'
import useReferralsData from 'src/hooks/useReferralsData'

type Props = {
  closeModal: () => void
  educatorName: string
  referralId: string
  referralVariant: ReferChipVariant
  showModal: boolean
}

const ReferralModal = ({ closeModal, educatorName, referralId, referralVariant, showModal }: Props) => {
  const initialValues = {
    variant: referralVariant,
  }

  const { loading, refetchAllData, updateReferralBonusShare } = useReferralsData({ skipFetchingData: true })
  const options = getReferralModalOptions()

  const handleReferralBonusShareUpdate = (values: { variant: ReferChipVariant }) => {
    const bonusShareRatio = invert(bonusShareRatioToVariant)[values.variant]

    updateReferralBonusShare({ variables: { update: { id: referralId, bonusShareRatio } } }).then(() => {
      refetchAllData()
      !loading && closeModal()
    })
  }

  return (
    <NewModalWrapper
      backdropOpacity={0.5}
      containerStyle={styles.modalContent}
      isVisible={showModal}
      onBackdropPress={closeModal}
      onSwipeComplete={closeModal}
      propagateSwipe
      style={styles.modal}
      swipeDirection={['down']}
    >
      <View style={styles.container}>
        <View style={styles.drawer} />
        <Text style={styles.title}>{translate(translations.referralsModalTitle, { name: educatorName })}</Text>
        <Formik onSubmit={handleReferralBonusShareUpdate} initialValues={initialValues}>
          {({ dirty, errors, handleSubmit, setFieldValue, touched, values }) => (
            <>
              <InputRadioGroup
                field={{
                  name: 'variant',
                  value: values.variant,
                }}
                form={{ errors, touched, setFieldValue }}
                highlightSelectedRow
                isRowPressable
                options={options}
                rowStyle={styles.radioInputOption}
              />
              <View style={styles.buttonsWrapper}>
                <ButtonNew
                  isLong
                  onPress={closeModal}
                  size={buttonSize.lg}
                  title={translate(translations.cancel)}
                  variant={buttonVariants.outlinedDefault}
                />
                <ButtonNew
                  disabled={!dirty}
                  isLong
                  onPress={handleSubmit}
                  size={buttonSize.lg}
                  title={translate(translations.select)}
                  variant={buttonVariants.containedDefault}
                />
              </View>
            </>
          )}
        </Formik>
        <View style={styles.base} />
      </View>
    </NewModalWrapper>
  )
}

export default ReferralModal
