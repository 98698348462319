import React, { ElementType, useMemo } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'

import Icon from 'src/components/Icon'
import styles from './styles'
import { IconTypes } from 'src/icons/iconTypes'

type Props = {
  disabled: boolean
  fieldIcon: ElementType<IconTypes>
  fieldLabel: string
  fieldValue: string | boolean | Date
  hasError: boolean
  onPress: () => void
}

const TouchableContentNew = ({ disabled, fieldIcon: FieldIcon, fieldLabel, fieldValue, hasError, onPress }: Props) => {
  const textStyle = useMemo(() => (fieldValue ? styles.fieldValue : styles.placeholder), [fieldValue])

  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={[styles.textInput, hasError && styles.errorInput, disabled && styles.disabledInput]}
      testID="country-picker"
    >
      {FieldIcon ? (
        <View style={styles.iconWrapper}>
          <FieldIcon size={24} style={styles.icon} />
          <Text style={textStyle}>{fieldLabel}</Text>
        </View>
      ) : (
        <Text style={textStyle}>{fieldLabel}</Text>
      )}
      <Icon name="arrow-down" size={14} style={styles.arrow} />
    </TouchableOpacity>
  )
}

export default TouchableContentNew
