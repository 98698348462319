import React, { useState } from 'react'
import { StyleProp, StyleSheet, TextInput, TextStyle, View, ViewStyle } from 'react-native'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import SecureButton from 'src/components/SecureButton'
import { Error, Label } from 'src/components/Form/Common'

import inputStyles from '../InputText/styles'
import styles from './styles'
import { KeyboardTypeOptions } from 'react-native/Libraries/Components/TextInput/TextInput'

// prepare type for props InputTextPassword using typeScript type
type Props = {
  containerStyles?: StyleProp<ViewStyle>
  editable?: boolean
  errorInputStyle?: StyleProp<ViewStyle>
  errorMessageStyle?: StyleProp<TextStyle>
  field: {
    name: string
    onBlur: (name: string) => void
    onChange: (name: string) => (text: string) => void
    value: string
  }
  form: {
    errors: object
    touched: object
    setFieldTouched: (name: string) => void
  }
  inputStyle?: StyleProp<ViewStyle>
  keyboardType?: KeyboardTypeOptions | undefined
  label?: string
  labelStyles?: StyleProp<ViewStyle>
  loginView?: boolean
  onSubmitEditing?: () => void
  placeholder?: string
  placeholderTextColor?: string
  required?: boolean
  forwardRef?: React.Ref<any>
}

const InputTextPassword = ({
  containerStyles,
  editable = true,
  errorInputStyle,
  errorMessageStyle,
  field: { name, onBlur, onChange, value },
  form: { errors, touched, setFieldTouched },
  forwardRef,
  inputStyle,
  keyboardType,
  label,
  labelStyles,
  loginView,
  onSubmitEditing,
  placeholder,
  placeholderTextColor,
  required,
}: Props) => {
  const [active, setActive] = useState(false)
  const [isSecured, setSecured] = useState(true)
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  return (
    <View style={StyleSheet.flatten([inputStyles.container, containerStyles])}>
      <Label label={label} labelStyles={StyleSheet.flatten([inputStyles.label, labelStyles])} required={required} />
      <View style={styles.inputContainer}>
        <TextInput
          autoCapitalize="none"
          editable={editable}
          keyboardType={keyboardType}
          onSubmitEditing={onSubmitEditing}
          placeholder={placeholder}
          placeholderTextColor={placeholderTextColor}
          ref={forwardRef}
          secureTextEntry={isSecured}
          value={value}
          onFocus={() => setActive(true)}
          onChangeText={text => onChange(name)(text)}
          onBlur={() => {
            setFieldTouched(name)
            setActive(false)
            onBlur(name)
          }}
          style={StyleSheet.flatten([
            inputStyles.textInput,
            active && inputStyles.touchInput,
            hasError && errorInputStyle,
            inputStyle,
          ])}
          testID={name}
        />

        <SecureButton
          centered
          loginView={loginView}
          onSecureButtonPress={() => setSecured(prev => !prev)}
          secureTextEntryDisabled={!isSecured}
        />
      </View>
      <Error errorMessageStyle={errorMessageStyle} errors={isTouched ? errors : {}} name={name} />
    </View>
  )
}

export default InputTextPassword

InputTextPassword.propTypes = {
  containerStyles: PropTypes.shape({}),
  errorInputStyle: PropTypes.shape({}),
  errorMessageStyle: PropTypes.shape({}),
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    setFieldTouched: PropTypes.func,
    touched: PropTypes.shape({}),
  }).isRequired,
  label: PropTypes.string,
  loginView: PropTypes.bool,
  placeholderTextColor: PropTypes.string,
  required: PropTypes.bool,
}
