import React, { useState } from 'react'
import { StyleProp, StyleSheet, TextInput, TextStyle, View, ViewStyle } from 'react-native'
import get from 'lodash/get'

import { Error, Label } from 'src/components/Form/Common'

import styles from './styles'
import { KeyboardTypeOptions, TextInputProps } from 'react-native/Libraries/Components/TextInput/TextInput'

type Props = {
  autoCapitalize?: string
  blurOnSubmit?: boolean
  containerStyles?: StyleProp<ViewStyle>
  editable?: boolean
  errorInputStyle?: object
  errorMessageStyle?: object
  field: {
    name: string
    onBlur: (name: string) => void
    onChange: (name: string) => (text: string) => void
    value: string
  }
  form: {
    errors: object
    touched: object
    setFieldTouched: (name: string) => void
  }
  inputStyle?: StyleProp<ViewStyle>
  keyboardType?: KeyboardTypeOptions | undefined
  label?: string
  labelStyles?: StyleProp<TextStyle>
  multiline?: boolean
  onChangeEffect?: (text: string) => void
  onSubmitEditing?: () => void
  placeholder?: string
  placeholderTextColor?: string
  required?: boolean
  returnKeyType?: string
  secureTextEntry?: boolean
  forwardRef?: React.Ref<any>
} & TextInputProps

const InputText = ({
  autoCapitalize,
  blurOnSubmit,
  containerStyles,
  editable = true,
  errorInputStyle,
  errorMessageStyle,
  field: { name, onBlur, onChange, value },
  form: { errors, touched, setFieldTouched },
  inputStyle,
  keyboardType,
  label,
  labelStyles,
  multiline = false,
  onChangeEffect,
  onSubmitEditing,
  placeholder,
  placeholderTextColor,
  required,
  returnKeyType,
  secureTextEntry,
  forwardRef,
}: Props) => {
  const [active, setActive] = useState(false)
  const isTouched = get(touched, name)
  const hasError = isTouched && get(errors, name)

  return (
    <View style={StyleSheet.flatten([styles.container, containerStyles, multiline && styles.multilineContainer])}>
      <Label labelStyles={StyleSheet.flatten([styles.label, labelStyles])} label={label} required={required} />
      <TextInput
        autoCapitalize={autoCapitalize}
        blurOnSubmit={blurOnSubmit}
        editable={editable}
        keyboardType={keyboardType}
        multiline={multiline}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        onBlur={() => {
          setFieldTouched(name)
          setActive(false)
          onBlur(name)
        }}
        onChangeText={text => {
          onChange(name)(text)
          onChangeEffect && onChangeEffect(text)
        }}
        onFocus={() => {
          setActive(true)
        }}
        ref={forwardRef}
        returnKeyType={returnKeyType}
        secureTextEntry={secureTextEntry}
        style={StyleSheet.flatten([
          styles.textInput,
          active && styles.touchInput,
          hasError && (errorInputStyle || styles.errorInput),
          multiline && styles.multiline,
          inputStyle,
        ])}
        testID={name}
        value={value}
      />
      <Error errorMessageStyle={errorMessageStyle} errors={isTouched ? errors : {}} name={name} />
    </View>
  )
}

export default InputText
