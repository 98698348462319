import gql from 'graphql-tag'

export default gql`
  fragment ExpressBookingFields on ExpressBooking {
    appointedAt
    bookingAppointed
    date
    description
    expressBookingPool {
      competences {
        id
        name
      }
      description
      id
      levels {
        id
        title
        group
      }
      title
    }
    holidayPayRate
    id
    isMatching
    salaryRate
    status
    studentAppointed
  }
`
