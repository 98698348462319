import { Dimensions, StyleSheet } from 'react-native'

import { colorsNew, typography, paddingNew } from 'src/styles'

export const MAX_HEIGHT = Dimensions.get('window').height * 0.8

export default StyleSheet.create({
  blockedScreenInfo: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
    textAlign: 'center',
  },
  blockedScreenTitle: {
    ...typography.h6,
    color: colorsNew.redesign.text.primary,
  },
  blockedScreenWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: MAX_HEIGHT,
    paddingHorizontal: paddingNew.lg,
  },
})
