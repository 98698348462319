import indexOf from 'lodash/indexOf'
import uniq from 'lodash/uniq'
import validate from 'validate.js'

import { userState } from 'src/global/constants'
import navigationService from 'src/utils/navigationService'
import { validation as preferencesValidation } from 'src/scenes/Main/Matching/Preferences/validation'
import translations, { translate } from 'src/utils/translations/translations'
import { LocaleT } from './translations/i18nTypes'
import { AddressT, UserAttributesT } from 'src/store/user/userTypes'

export const handlePreferencesValidation = (preferencesErrors: { [key: string]: string[] }) => {
  navigationService.navigate('Modal', {
    description: `${translate(translations.missingFields)}\n${parseErrors(preferencesErrors)}`,
    title: translate(translations.missingPreferencesDetails),
    preferencesModal: true,
  })
}

export const userStates = (countryCode: LocaleT) => Object.values(userState[countryCode])

export const isUserAccepted = (state: UserAttributesT['state'], countryCode: LocaleT) =>
  indexOf(userStates(countryCode), state) >= indexOf(userStates(countryCode), userState[countryCode].accepted)

export const validatePreferences = (userAttributes: UserAttributesT) => {
  const attributes = {
    ...userAttributes,
    homeAddress: userAttributes.address,
  }
  return {
    preferencesErrors: validate(attributes, preferencesValidation),
  }
}

export const parseErrors = (...args: { [key: string]: string[] }[]) => {
  const errors = args.reduce((acc, object) => Object.assign(acc, object), {})

  return Object.keys(errors)
    .map(fieldName => `- ${translate(fieldName.replace('homeAddress.', ''))}`)
    .join('\n')
}

export const subjects = (topics: { title: string }[]) =>
  uniq(topics.map(t => t.title))
    .join(', ')
    .toString()
